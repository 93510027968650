import React from "react";
import MinerPage from "./MinerPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MinerPageContextProvider } from "./context/MinerPageContextProvider";

const MinerInventoryPage = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            mutations: {
                // Default options for all mutations
                retry: 1, // Number of retry attempts
                onSuccess: (data) => {
                    console.log("Mutation succeeded:", data);
                },
                onError: (error) => {
                    console.error("Mutation failed:", error);
                },
            },
        },
    });
    return (
        <MinerPageContextProvider>
            <QueryClientProvider client={queryClient}>
                <MinerPage />
            </QueryClientProvider>
        </MinerPageContextProvider>
    );
};

export default MinerInventoryPage;
