import { styled, Typography } from "@mui/material";

const H1DisplayLarge = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "3.5625rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "4rem",
  letterSpacing: "-0.01563rem",
});

const H2DisplayMedium = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "2.8125rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "3.25rem",
});

const H3DisplaySmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "2.25rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "2.75rem",
});

const H4HeadlineLarge = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "2.5rem",
});

const H5HeadlineMedium = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1.75rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "2.25rem",
});

const H6HeadlineSmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "2rem",
});

const H7TitleLarge = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1.375rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1.75rem",
});

const H8TitleMedium = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1.5rem",
  letterSpacing: "0.00938rem",
});

const H9TitleSmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1.25rem",
  letterSpacing: "0.00625rem",
});

const LabelLarge = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1.25rem",
  letterSpacing: "0.00625rem",
});

const LabelMedium = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1rem",
  letterSpacing: "0.03125rem",
});

const LabelSmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.6875rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1rem",
  letterSpacing: "0.03125rem",
});

const BodyLarge = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1.5rem",
  letterSpacing: "0.03125rem",
});

const BodyMedium = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1.25rem",
  letterSpacing: "0.01563rem",
});

const BodySmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1rem",
  letterSpacing: "0.025rem",
});

const H10TitleXSmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.5rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "0.75rem",
  letterSpacing: "0.00469rem",
});

const BodyXSmall = styled(Typography)({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "0.4rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "0.5rem",
  letterSpacing: "0.013rem",
});

const DataLegend = styled(Typography)({
  color: "#262527",
  fontFamily: "Roboto",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "0.875rem",
});

export {
  H1DisplayLarge,
  H2DisplayMedium,
  H3DisplaySmall,
  H4HeadlineLarge,
  H5HeadlineMedium,
  H6HeadlineSmall,
  H7TitleLarge,
  H8TitleMedium,
  H9TitleSmall,
  H10TitleXSmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  BodyLarge,
  BodyMedium,
  BodySmall,
  BodyXSmall,
  DataLegend,
};
