import { Box, Skeleton } from "@mui/material";
import { H8TitleMedium } from "../../../components/StyledComponents/Typography/Typography.tsx";


const UserSubscriptionCardLoading = () => {
    return (
        <Box
            className={"content-container"} d
            padding={"2rem"}
            border={"1px solid #C4C5D6"}
            borderRadius={"1.5rem"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
        >
            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} >
                <H8TitleMedium>Subscribe for reports</H8TitleMedium>
            </Box>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} height={"3rem"} >
                    <Skeleton width={"15rem"} variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
                </Box>
                <Skeleton width={"7rem"} variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
            </Box>
        </Box>
    )
};

export default UserSubscriptionCardLoading;