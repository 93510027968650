import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Typography } from "@mui/material";
import * as sanitizeHtml from "sanitize-html";
import * as util from "./util";
import * as api from "../api/api";

// import preval from 'preval.macro';
// const buildTimestamp = preval`module.exports = new Date().toISOString();`;
// console.log(buildTimestamp)

export default function PrivacyAndTermsDialog({
  name,
  buttonTitle,
  title,
  page,
  replacementButton,
  open,
  setopen,
}) {
  const [html, setHtml] = React.useState("");

  const handleClickOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  React.useEffect(() => {
    const fn = async () => {
      const resp = await fetch(page);
      const dirty = await resp.text();
      const clean = sanitizeHtml(dirty, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "head",
          "body",
          "table",
          "tr",
          "thead",
          "td",
          "img",
          "br",
          "hr",
          "area",
          "base",
          "basefont",
          "input",
          "link",
          "meta",
          "style",
        ]),
        allowedAttributes: {
          a: ["name", "target", "href"],
          img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
        },
        allowedClasses: {
          "*": ["*"],
        },
        transformTags: {
          html: "div",
          head: "div",
          body: "div",
        },
        allowVulnerableTags: true,
        exclusiveFilter: function (frame) {
          if (frame.tag === "div") {
            // filter out foot notes for now
            // if (frame.attribs['class'] === 'c10' || frame.attribs['class'] === 'c19') {
            //     return true
            // }
          }
          return false;
        },
      });

      setHtml(clean);
    };
    fn();
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetPrivacy(token, (data) => {
        // console.log({ data: data })
        if (util.isPrivacyAgreed(data, name)) {
          // setOpen(false)
        } else {
          setopen(true);
        }
      });
    };
    fn();
  }, []);

  const handleAgree = () => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.PostPrivacy(token, {
        name: name,
      });
      setopen(false);
    };
    fn();
  };

  return (
    <div>
      <Dialog maxWidth="lg" fullWidth open={open}>
        <AppBar sx={{ position: "sticky" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleAgree}>
              I Agree
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 5 }}>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </Box>
      </Dialog>
    </div>
  );
}
