import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  BodyLarge,
  H6HeadlineSmall,
  H8TitleMedium,
  DataLegend,
  LabelLarge,
  BodySmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import AuradineErrorState from "../../../components/StyledComponents/EmptyStates/AuradineErrorState.tsx";
import { useHistory } from "react-router-dom";
import { FetchHeatMapDataBySerialNumber } from "../../../api/api.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import { useQuery } from "@tanstack/react-query";
import { computeRowsUpdates } from "@mui/x-data-grid/hooks/features/rows/gridRowsUtils";
import { objectShallowCompare } from "@mui/x-data-grid/hooks/utils/useGridSelector";
import AuradineDataNotFoundState from "../../../components/StyledComponents/EmptyStates/AuradineDataNotFoundState.tsx";
import {
  AT1_chipLayout,
  AI25_chipLayout,
  AT28_chipLayout,
  AI36_chipLayout,
  AD_chipLayout,
} from "../../../constants.js";
import { flatMap, sortBy } from "lodash";
import StyledToolTip from "../../../components/StyledComponents/ToolTip/ToolTip";

const ColorSquare = (props) => {
  const { color } = props;
  return (
    <div
      style={{ width: "12px", height: "12px", backgroundColor: `${color}` }}
    />
  );
};

const createHashboardChipData = () => {
  const hashboardChipMockData = [];
  for (let i = 1; i <= 200; i++) {
    hashboardChipMockData.push({
      id: i + 100,
      frequency: Math.floor(Math.random() * 100),
      temperature: Math.floor(Math.random() * 100),
    });
  }

  return hashboardChipMockData;
};

const ToolTipBody = React.memo((props) => {
  const { data } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      paading={"0.5rem"}
      gap={1}
      className={"tooltip"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        className={"tooltip-header"}
      >
        <H8TitleMedium>{`ID: ${data.id}`}</H8TitleMedium>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={4}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          className={"frequency-datapoint"}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: data.freqColor }}
          >
            vital_signs
          </span>
          <BodySmall>{`${data.frequency.toFixed(
            2
          )} MHz`}</BodySmall>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          className={"temperature-datapoint"}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: data.tempColor }}
          >
            thermometer
          </span>
          <BodySmall>{`${data.temperature.toFixed(2)} °C`}</BodySmall>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          className={"voltage-datapoint"}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: data.voltageColor }}
          >
            electric_bolt
          </span>
          <BodySmall>{`${data.voltage.toFixed(2)} V`}</BodySmall>
        </Box>
      </Box>
    </Box>
  );
});

const ChipComponent = (props) => {
  const { data, index } = props;
  console.log(data)
  return (
    <StyledToolTip
      title={<ToolTipBody data={data} />}
      placement={"bottom"}
      arrow
      disabled={data?.id === -1}
    >
      <Box
        key={index}
        display="flex"
        flexDirection="row"
        padding={"0.375rem 0.5rem"}
        gap={1}
        borderRadius={"0.5rem"}
        border={
          [data.freqColor, data.tempColor, data.voltageColor].includes(
            "rgba(255, 84, 73, 1)"
          )
            ? "1px solid rgba(255, 84, 73, 1)"
            : [data.freqColor, data.tempColor, data.voltageColor].includes(
              "rgba(248, 194, 104, 1)"
            )
              ? "1px solid rgba(248, 194, 104, 1)"
              : "1px solid rgba(29, 27, 32, 0.12)"
        }
        flexShrink={0}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        sx={{ cursor: "pointer !important" }}
      >
        <LabelLarge>{data.id}</LabelLarge>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <span
            className="material-symbols-outlined"
            style={{
              width: "1.125rem",
              height: "1.125rem",
              color: data.freqColor ?? "#000",
            }}
          >
            vital_signs
          </span>
          <span
            className="material-symbols-outlined"
            style={{
              width: "1.125rem",
              height: "1.125rem",
              color: data.tempColor ?? "#000",
            }}
          >
            thermometer
          </span>
          <span
            className="material-symbols-outlined"
            style={{
              width: "1.125rem",
              height: "1.125rem",
              color: data.voltageColor ?? "#000",
            }}
          >
            electric_bolt
          </span>
        </Box>
      </Box>
    </StyledToolTip>
  );
};

const HashboardPageHeader = () => {
  const navigate = useHistory();
  return (
    <Box display="flex" flexDirection="row" gap={2.48} marginBottom={"1.5rem"}>
      <Box
        display="flex"
        flexDirection="column"
        minWidth={"2.98rem"}
        justifyContent="flex-start"
        alignItems={"center"}
      >
        <StyledIconButton onClick={() => navigate.goBack()}>
          {" "}
          <ArrowBackIosNewIcon fontSize="mediun" color="#000" />{" "}
        </StyledIconButton>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="flex-start"
      >
        <H6HeadlineSmall>Hash Boards</H6HeadlineSmall>
        <BodyLarge>
          Monitor key environmental metrics—frequency, temperature, and
          voltage—across every ASIC to quickly assess their health and identify
          areas needing attention. Stay on top of ASIC performance to ensure
          optimal mining efficiency.
        </BodyLarge>
      </Box>
    </Box>
  );
};

const HashboardHeatMapTableLegend = () => {
  return (
    <Grid container display="flex" gap={4} flexGrow={1}>
      <Grid item xs={6}>
        <Box
          width={"100%"}
          flexGrow={1}
          className={"legend-top-left-container"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gap={4}
        >
          <Box
            display="flex"
            className={"legend-title-with-icon"}
            flexDirection="row"
            alignItems={"center"}
            gap={4}
          >
            <H8TitleMedium>Frequency</H8TitleMedium>
            <span
              className="material-symbols-outlined"
              style={{ height: "24px", width: "24px" }}
            >
              vital_signs
            </span>
            <Box display={"flex"} gap={8} alignItems={"center"}>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#B6C4FF" />
                <DataLegend>-2000 - -1001</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#678AFF" />
                <DataLegend>-1000 - 199.99</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#2D55C9" />
                <DataLegend>200 - 2000.99</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#00277E" />
                <DataLegend>2001 - 3000</DataLegend>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ width: "0.0625rem", height: "1.5rem" }}
                flexItem
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5.5}>
        <Box
          width={"100%"}
          flexGrow={1}
          className={"legend-top-left-container"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          gap={4}
        >
          <Box
            display="flex"
            className={"legend-title-with-icon"}
            flexDirection="row"
            alignItems={"center"}
            gap={4}
          >
            <H8TitleMedium>Voltage</H8TitleMedium>
            <span
              className="material-symbols-outlined"
              style={{ height: "24px", width: "24px" }}
            >
              electric_bolt
            </span>
            <Box display={"flex"} gap={8} alignItems={"center"}>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#B6C4FF" />
                <DataLegend>-2000 - -1001</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#678AFF" />
                <DataLegend>0-1000 - 0.17</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#2D55C9" />
                <DataLegend>0.18 - 0.44</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#00277E" />
                <DataLegend>0.45 - 1</DataLegend>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          width={"100%"}
          flexGrow={1}
          className={"legend-top-left-container"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          gap={4}
        >
          <Box
            display="flex"
            className={"legend-title-with-icon"}
            flexDirection="row"
            alignItems={"center"}
            gap={4}
          >
            <H8TitleMedium>Temperature</H8TitleMedium>
            <span
              className="material-symbols-outlined"
              style={{ height: "24px", width: "24px" }}
            >
              thermometer
            </span>
            <Box display={"flex"} gap={8} alignItems={"center"}>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#B6C4FF" />
                <DataLegend>-2000 - -1001</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#8FA7FF" />
                <DataLegend>-1000 - 0</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#678AFF" />
                <DataLegend>25-49.99</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#4B6FE3" />
                <DataLegend>50-74.99</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#013BB0" />
                <DataLegend>75-99.99</DataLegend>
              </Box>
              <Box
                className="legend-values"
                display={"flex"}
                flexDirection="row"
                gap={2}
              >
                <ColorSquare color="#00277E" />
                <DataLegend>100-300</DataLegend>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const heatmapColors = (value, type) => {
  if (value === undefined || value === null) return undefined; // check for undefined or null

  if (type === "frequency") {
    if (value >= -2000 && value <= -1000) return "#B6C4FF";
    if (value > -1000 && value <= 199.99) return "#678AFF";
    if (value > 200 && value <= 2000.99) return "#2D55C9";
    if (value > 2001 && value <= 3000) return "#00277E";
  }

  if (type === "temperature") {
    if (value >= -2000 && value <= -1001) return "#B6C4FF";
    if (value > -1000 && value <= 0) return "#8FA7FF";
    if (value > 0.01 && value <= 24.99) return "#678AFF";
    if (value > 25 && value <= 49.99) return "#4B6FE3";
    if (value > 50 && value <= 74.99) return "#2D55C9";
    if (value > 75 && value <= 99.99) return "#013BB0";
    if (value > 100 && value <= 300) return "#00277E";
  }

  if (type === "voltage") {
    if (value >= -2000 && value <= -1001) return "#B6C4FF";
    if (value > -1000 && value <= 0.17) return "#678AFF";
    if (value > 0.18 && value <= 0.44) return "#2D55C9";
    if (value > 0.45 && value <= 1) return "#00277E";
  }

  return undefined; // default if no conditions are met
};

const findChipLayout = (model) => {
  if (!model) return "";
  if (model.toLowerCase().includes("at1")) {
    return AT1_chipLayout;
  } else if (model.toLowerCase().includes("ai25")) {
    return AI25_chipLayout;
  } else if (model.toLowerCase().includes("at28")) {
    return AT28_chipLayout;
  } else if (model.toLowerCase().includes("ad")) {
    return AD_chipLayout;
  } else if (model.toLowerCase().includes("ai36")) {
    return AI36_chipLayout;
  }

  return ""; // default if no conditions are met
};

const HashboardHeatMap = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { authToken } = useAppContextProvider();
  const mockData = createHashboardChipData();
  const navigate = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const serialNo = urlParams.get("serialNo");

  const {
    data: HeatMapData,
    isLoading,
    isError,
    isFetched,
  } = useQuery({
    queryKey: ["hashboardHeatMap", serialNo, authToken],
    queryFn: () =>
      FetchHeatMapDataBySerialNumber({ authToken, serialNumber: serialNo }),
    enabled: !!serialNo && !!authToken,
    staleTime: 1000 * 60 * 5,
  });

  const hashboardHeatMapData = HeatMapData?.docs;

  const hashboardHeatMapDataMapped = HeatMapData?.docs.map((data) => {
    const { Model, Frequency, Temperatures, voltage } = data;

    const FrequencyData = Frequency?.Frequency;
    const FrequencyDataMap = FrequencyData?.filter((data) => {
      const { Name: name } = data;
      return name.toLowerCase().includes("hash board");
    }).reduce((acc, data) => {
      return { ...acc, [data?.Name]: data?.ChipFrequency };
    }, {});

    const TemperatureData = Temperatures?.Temperature;
    console.log("TemperatureData", TemperatureData);
    const TemperatureDataMap = TemperatureData?.filter(
      (data) => data?.Name?.toLowerCase().includes("hash board")
    ).reduce((acc, data) => {
      return { ...acc, [data?.Name]: data?.ChipTemp };
    }, {});
    console.log("TemperatureDataMap", TemperatureDataMap);

    const voltageData = voltage?.Voltage;
    const voltageDataMap = voltageData
      ?.filter((data) => data?.Name?.toLowerCase().includes("hash board"))
      .reduce((acc, data) => {
        return { ...acc, [data?.Name]: data?.ChipVoltage };
      }, {});

    const chipLayout = flatMap(findChipLayout(Model));

    const TempFreqAndVoltData = FrequencyDataMap
      ? Object.entries(FrequencyDataMap).map(([key, value]) => {
        console.log("key", key);
        const TemperatureData = TemperatureDataMap[key];
        const voltageData = voltageDataMap[key];
        console.log("TemperatureData", TemperatureData);
        console.log("voltageData", voltageData);

        // Map through the frequency data and sort them according to chipLayout
        let sortedData = sortBy(
          value?.map((data) => {
            return {
              id: data.ID,
              frequency: data.Frequency,
              temperature: TemperatureData?.find(
                (tempData) => data?.ID === tempData?.ID
              )?.Temperature,
              voltage: voltageData?.find(
                (voltData) => voltData?.ID === data?.ID
              )?.Voltage,
              voltageColor: heatmapColors(
                voltageData?.find((voltData) => voltData?.ID === data?.ID)
                  ?.Voltage,
                "voltage"
              ),
              tempColor: heatmapColors(
                TemperatureData?.find((tempData) => data?.ID === tempData?.ID)
                  ?.Temperature,
                "temperature"
              ),
              freqColor: heatmapColors(data.Frequency, "frequency"),
            };
          }),
          (arrayItem) => {
            return chipLayout.findIndex((chipId) => arrayItem.id === chipId);
          }
        );


        // Insert objects with id: -1 where chipLayout contains -1
        sortedData = chipLayout.map((chipId, index) => {
          if (chipId === -1) {
            return {
              id: -1,
              frequency: undefined,
              temperature: undefined,
              voltage: undefined,
              voltageColor: undefined,
              tempColor: undefined,
              freqColor: undefined,
            };
          }
          return (
            sortedData.find((data) => data.id === chipId) || {
              id: chipId,
              frequency: undefined,
              temperature: undefined,
              voltage: undefined,
              voltageColor: undefined,
              tempColor: undefined,
              freqColor: undefined,
            }
          );
        });

        return { [key]: sortedData };
      })
      : [];

    console.log("TempFreqAndVoltData", TempFreqAndVoltData);
    return { Model, TempFreqAndVoltData };
  });

  const hashboardHeatMapDataFinal = hashboardHeatMapDataMapped?.reduce(
    (acc, data) => {
      return { ...acc, ...data };
    },
    {}
  );
  console.log("hashboardHeatMapDataFinal", hashboardHeatMapDataFinal);
  const numberOfHashboards =
    hashboardHeatMapDataFinal?.TempFreqAndVoltData.length;

  return (
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <HashboardPageHeader />
      {serialNo && (
        <Box
          className={"miner-page-table"}
          display={"flex"}
          flexDirection={"column"}
          // backgroundColor={"#F3F2FD"}
          sx={{
            backgroundColor: "#F3F2FD",
          }}
          padding={"0.75rem 0.75rem 0rem 0.75rem"}
          borderRadius={"1rem"}
          component={"div"}
        >
          <HashboardHeatMapTableLegend />
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            variant="fullWidth"
            textColor="inherit"
            indicatorColor="primary"
            sx={{
              paddingBottom: "0.5rem",
              "& .MuiTab-root": {
                textTransform: "none",
                color: "#444653",
                fontFamily: "Roboto",
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.25rem",
              },
              "& .Mui-selected": {
                color: "#2D55C9", // Custom text color for selected tab
              },
              "& .MuiTabs-indicator": {
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#2D55C9",
                maxWidth: "5%",
                height: "4px",
                borderTopLeftRadius: 100,
                borderTopRightRadius: 100,
                marginLeft:
                  numberOfHashboards === 2
                    ? "22.5%"
                    : numberOfHashboards === 1
                      ? "47.5%"
                      : "14%",
              },
              "& .MuiTabs-indicatorSpan": {
                maxWidth: " 10 !important",
                width: "100%",
                backgroundColor: "#2D55C9",
              },
            }}
          >
            {isLoading && (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={"3.0625rem"}
                sx={{
                  backgroundColor: "rgba(232, 231, 241, 1)",
                  borderRadius: "0.5rem",
                }}
              />
            )}
            {isFetched &&
              hashboardHeatMapDataFinal?.TempFreqAndVoltData.length > 0 &&
              hashboardHeatMapDataFinal?.TempFreqAndVoltData.map(
                (data, index) => <Tab label={`Board ${index + 1}`} />
              )}
          </Tabs>

          {isLoading && (
            <Box width={"100%"} height={"57vh"} overflow={"auto"}>
              <Grid container spacing={2} margin={0}>
                {mockData.map((data, index) => (
                  <Grid item xs={1} key={index}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={"100%"}
                      height={"2rem"}
                      sx={{
                        backgroundColor: "rgba(232, 231, 241, 1)",
                        borderRadius: "0.5rem",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {isError && !isFetched && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexGrow={1}
              pb={"2%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {" "}
              <AuradineErrorState message="Something went wrong!!" />{" "}
            </Box>
          )}
          {isFetched && HeatMapData?.status.toLowerCase() === "error" && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexGrow={1}
              pb={"2%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {" "}
              <AuradineErrorState message={"Something went wrong"} />{" "}
            </Box>
          )}
          {isFetched &&
            hashboardHeatMapDataFinal?.TempFreqAndVoltData.length === 0 && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                pb={"2%"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {" "}
                <AuradineDataNotFoundState
                  message={`No data for device with serial number ${serialNo}`}
                />{" "}
              </Box>
            )}
          {isFetched &&
            hashboardHeatMapDataFinal?.TempFreqAndVoltData.length > 0 &&
            hashboardHeatMapDataFinal?.TempFreqAndVoltData.map(
              (hashboardData, index) => {
                return (
                  selectedTab === index && (
                    <Box
                      width={"100.0%"}
                      height={"57vh"}
                      sx={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                      <Grid container spacing={1} margin={0}>
                        {hashboardData &&
                          hashboardData[`Hash Board ${index + 1}`] ===
                          undefined && (
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              flexGrow={1}
                              pb={"2%"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <AuradineDataNotFoundState
                                message={`No data for Hash Board ${index + 1}`}
                              />
                            </Box>
                          )}
                        {hashboardData &&
                          hashboardData[`Hash Board ${index + 1}`] &&
                          hashboardData[`Hash Board ${index + 1}`].map(
                            (data, index) => (
                              <Grid item xs={0.99} key={index}>
                                <ChipComponent data={data} index={index} />
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Box>
                  )
                );
              }
            )}
        </Box>
      )}
      {!serialNo && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          pb={"2%"}
          flexGrow={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <AuradineErrorState message="Please provide a serial number!!" />
        </Box>
      )}
    </Container>
  );
};

export default HashboardHeatMap;
