/**
 * Renders a user subscription card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.userData - The user data.
 * @param {boolean} props.isFetched - Indicates if the user data has been fetched.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered user subscription card component.
 */
import React from 'react'
import StyledSwitch from '../../../components/StyledComponents/Switch/Switch'
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { BodyLarge, H8TitleMedium } from '../../../components/StyledComponents/Typography/Typography.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostUser, PostUserSubscriptionPreference } from '../../../api/api';
import useAppContextProvider from '../../../AppContext/useAppContextProvider.js';
import User from '../../User.js';
import UserSubscriptionCardLoading from './UserSubscriptionCardLoading.js';


const UserSubscriptionCard = (props) => {
    const { userData, isFetched, isLoading } = props;
    const { setToastMessage, setToastOpen, authToken } = useAppContextProvider();
    const queryClient = useQueryClient();


    const { control, reset } = useForm({
        defaultValues: {
            isSubscribedToWeeklyReports: false,
        }
    });
    React.useEffect(() => {
        if (userData) {
            reset({
                isSubscribedToWeeklyReports: userData?.user?.isSubscribedToWeeklyReports,
            });
        }
    }, [userData, reset, isFetched]);
    const mutation = useMutation({
        mutationFn: ({ token, data }) => PostUserSubscriptionPreference(token, data),
        onSuccess: (data, variables) => {
            const { isSubscribedToWeeklyReports } = variables?.data;
            setToastOpen(true);
            if (isSubscribedToWeeklyReports) {
                setToastMessage("Subscribed to weekly reports");
            } else {
                setToastMessage("Unsubscribed from weekly reports");
            }
            queryClient.invalidateQueries({ queryKey: ['user'], refetchType: 'active' });
        },
        onError: () => {
            setToastOpen(true);
            setToastMessage("Failed to subscribe to weekly reports");
        }
    });

    const handleSwitchChange = (value) => {
        console.log(value);
        mutation.mutate({ token: authToken, data: { isSubscribedToWeeklyReports: value } });
    };


    return (
        <>
            {isFetched && <Box
                className={"content-container"} d
                padding={"2rem"}
                border={"1px solid #C4C5D6"}
                borderRadius={"1.5rem"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
            >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} >
                    <H8TitleMedium>Subscribe for reports</H8TitleMedium>
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} height={"3rem"} >
                        <BodyLarge>Receive weekly updates directly in your inbox.</BodyLarge>
                    </Box>
                    <Controller
                        key={userData?.isSubscribedToWeeklyReports}
                        name="isSubscribedToWeeklyReports"
                        control={control}
                        render={({ field }) => (
                            <StyledSwitch {...field} checked={field.value || false} onChange={(e) => {
                                field.onChange(e.target.checked);
                                handleSwitchChange(e.target.checked);
                            }} />
                        )}
                    />
                </Box>
            </Box>
            }
            {isLoading && <UserSubscriptionCardLoading />}
        </>
    )
}

export default UserSubscriptionCard