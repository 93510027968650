import { Box, Grid, Skeleton } from "@mui/material";
import { H8TitleMedium, H9TitleSmall } from "../../../components/StyledComponents/Typography/Typography.tsx";
import React from "react";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

export const UserProfileCardLoadingContainer = () => {
    return (
        <Grid
            container
            spacing={0}
            className={"content-container"} d
            padding={"2rem"}
            border={"1px solid #C4C5D6"}
            borderRadius={"1.5rem"}
        >
            <Grid item xs={5.75}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    gap={6}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                    >
                        <H8TitleMedium>Basic info</H8TitleMedium>
                    </Box>
                    <Box>
                        <Grid container spacing={6}>
                            <Grid item xs={1}>
                                <Skeleton variant="circular" width={40} height={40} />
                            </Grid>
                            <Grid item xs={10}>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"flex-start"}
                                    gap={4}
                                >
                                    <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                        <Grid item xs={2} > <H9TitleSmall>Name</H9TitleSmall> </Grid>
                                        <Grid item xs={10} ><Skeleton variant="text" sx={{ fontSize: "2rem" }} /> </Grid>
                                    </Grid>
                                    <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                        <Grid item xs={2}> <H9TitleSmall>Role</H9TitleSmall> </Grid>
                                        <Grid item xs={10}><Skeleton variant="text" sx={{ fontSize: "2rem" }} /> </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </Grid >
            <Grid item xs={5.75}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    gap={6}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                    >
                        <H8TitleMedium>Contact info</H8TitleMedium>
                    </Box>
                    <Box>
                        <Grid container >
                            <Grid item xs={12}>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"flex-start"}
                                    gap={4}
                                    flexGrow={1}
                                >
                                    <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} flexGrow={1} width={"100%"}>
                                        <Grid item xs={2}>
                                            <H9TitleSmall>Address</H9TitleSmall>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                        <Grid item xs={2}>
                                            <H9TitleSmall>Phone</H9TitleSmall>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                        <Grid item xs={2}>
                                            <H9TitleSmall>Email</H9TitleSmall>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={0.5}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <StyledIconButton>
                        <span className="material-symbols-outlined" style={{ color: "#000" }}>edit</span>
                    </StyledIconButton>
                </Box>
            </Grid>

        </Grid >
    );
}