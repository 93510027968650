import React from "react";
import {
  Box,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from "@mui/material";
import { H9TitleSmall } from "../../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const AggregatedTHs = React.memo((props) => {
  const { hashRateData } = props;
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="0.25rem 0.5rem 0.25rem 1rem"
        alignItems={"center"}
      >
        <H9TitleSmall> Aggregated THs </H9TitleSmall>
      </Box>
      <>
        <List
          sx={{
            width: "100%",
            backgroundColor: "#EEEDF7",
            borderRadius: "1.5rem",
          }}
        >
          <ListItem>
            <ListItemText>{hashRateData?.aggregatedTHsLastHour}</ListItemText>
          </ListItem>
        </List>
      </>
    </Box>
  );
});
