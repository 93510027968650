import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  ClickAwayListener,
  Typography,
  Popover,
  FormControl,
  InputAdornment,
  Input,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import {
  BodyLarge,
  BodySmall,
  H6HeadlineSmall,
  H7TitleLarge,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import { SecondaryButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { useState } from "react";

const StyledPopoverContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #f3f2fd;
  border-radius: 1rem;

  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 1rem; // Change this to the border radius you want
  }
`;

const StyledHeaderAndFooterBox = styled(Box)`
  padding: 1.5rem;
`;

const TagOperationsCard = (props) => {
  const { open, handleClose, anchorRef } = props;
  const [searchText, setSearchText] = useState("");

  // Original list of groups (used for resetting filter)
  const [allTags] = useState([
    "Tag 1",
    "Tag 2",
    "Tag 3",
    "Tag 4",
    "Tag 5",
    "Tag 6",
    "Tag 7",
    "Tag 8",
    "Tag 9",
    "Tag 10",
    "Tag 11",
  ]);

  // Filtered list of groups that gets displayed
  const [filteredTags, setFilteredTags] = useState(allTags);

  // Filter groups based on search text
  const filterGroups = (searchText) => {
    setSearchText(searchText);
    if (searchText.trim() === "") {
      setFilteredTags(allTags); // Reset to full list if search is empty
    } else {
      const result = allTags.filter((tag) =>
        tag.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTags(result);
    }
  };

  return (
    <StyledPopover
      sx={{ zIndex: 9999, borderRadius: "1rem" }}
      open={open}
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <StyledPopoverContent>
          <StyledHeaderAndFooterBox
            display={"flex"}
            flexDirection={"column"}
            gap={4}
          >
            <H7TitleLarge>Add tag:</H7TitleLarge>
            <BodySmall>Select which tags you’d like to mark.</BodySmall>
          </StyledHeaderAndFooterBox>
          <Box
            display="flex"
            flexDirection={"column"}
            padding="0rem 1.5rem"
            className={"search-box"}
          >
            {/* Search Input */}
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <Input
                id="standard-adornment-amount"
                placeholder="Search groups"
                value={searchText}
                onChange={(event) => filterGroups(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <CloseIcon />
                  </InputAdornment>
                }
              />
            </FormControl>

            {/* Groups List */}
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"22.5rem"}
              minWidth={"22.5rem"}
              maxHeight={"15rem"}
              minHeight={"15rem"}
              maxWidth={"45rem"}
              overflow={"auto"}
            >
              {filteredTags.map((group, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  padding={"0.25rem 1.5rem 0.25rem 1rem"}
                  height={"3rem"}
                  minHeight={"3rem"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  flexShrink={0}
                  flexGrow={0}
                  sx={{ cursor: "pointer" }}
                >
                  {group}
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            <List
              sx={{ padding: "0.25rem 1.5rem", borderTop: "2px solid #CAC4D0" }}
            >
              <ListItem
                sx={{ display: "flex", height: "3rem", minHeight: "3rem" }}
              >
                <ListItemText>
                  <BodyLarge> Add tags </BodyLarge>
                </ListItemText>
                <AddIcon />
              </ListItem>
              <ListItem
                sx={{ display: "flex", height: "3rem", minHeight: "3rem" }}
              >
                <ListItemText>
                  <BodyLarge> Manage tags</BodyLarge>
                </ListItemText>
                <ArrowRightIcon />
              </ListItem>
            </List>
          </Box>
        </StyledPopoverContent>
      </ClickAwayListener>
    </StyledPopover>
  );
};

export default TagOperationsCard;

export const TagOperationsTab = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          // paddingRight: "2rem",
        }}
      >
        <Tooltip title="More options">
          <SecondaryButton
            ref={anchorRef}
            onClick={handleClick}
            endIcon={
              <LocalOfferOutlinedIcon
                fontSize={"small"}
                sx={{ color: "#2D55C9" }}
              />
            }
          >
            Tag
          </SecondaryButton>
        </Tooltip>
      </Box>

      <TagOperationsCard
        open={open}
        handleClose={handleClose}
        anchorRef={anchorRef}
      />
    </React.Fragment>
  );
};
