import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import User from "./User";

const UserPage = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { retry: 0 },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <User />
    </QueryClientProvider>
  );
};

export default UserPage;
