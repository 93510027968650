// @ts-nocheck
import React, { useEffect } from "react";
import { Box, Grid, Container, Slide, CircularProgress, LinearProgress } from "@mui/material";
import { LeftSidePanel } from "./Components/LeftPanel/LeftPanel.js";
import "reactjs-popup/dist/index.css";
import GridOnIcon from "@mui/icons-material/GridOn";
import { RightSidePanel } from "./Components/RightPanel/RightPanel.js";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";
import { GridDetailPanelToggleCell } from '@mui/x-data-grid-pro';
import ArrowRight from "@mui/icons-material/ArrowRight";
import { useHistory, useLocation } from "react-router-dom";
import {
    BodyLarge,
    BodyMedium,
    H7TitleLarge,
} from "../components/StyledComponents/Typography/Typography.tsx";

import { MinerTableOperationsContainer } from "./Components/Operations/MinerTableOperationsContainer.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MinerTableHeader } from "./Components/MinerHeader/MinerHeader.js";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import useMinerPageContext from "./context/useMinerPageContext.js";
import DataGrid from "../components/DataGrid/DataGrid.tsx";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { GetUserUseQuery, PostNewMinerDevice } from "../api/api.js";
import { returnDefaultColumnConfigs, returnDefaultColumnConfigsForTable } from "./Utils/Utils.js";
import { isEmpty, set } from "lodash";
import { SecondaryButton } from "../components/StyledComponents/Buttons/AuradineButtons.js";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";


export const HeaderContainer = React.memo((props) => {
    return (
        <Box
            className={"header-container"}
            display={"flex"}
            justifyContent="flex-start"
            flexDirection="column"
            gap={4}
            marginBottom={"1.5rem"}
        >
            <Box
                className={"title-box"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={3.5}
            >
                <H7TitleLarge>Miner Inventory</H7TitleLarge>
                <BodyLarge>
                    Manage your miners individually or by organizing them into
                    groups based on sites or other criteria.
                </BodyLarge>
            </Box>
        </Box>
    );
});

const GetDetailPanelContent = ({ row, history, detailGroup }) => {
    if (!row) return <> </>;
    if (isEmpty(detailGroup)) return <></>;
    const columnsToShowWithData = row ? Object.entries(row).filter(([key, value]) => {
        const columns = detailGroup ? detailGroup?.map(item => item?.id) : []
        return columns.includes(key);
    }).reduce((acc, item) => {
        acc[item[0]] = item[1];
        return acc;
    }, {}) : [];

    const columnsTitles = detailGroup ? detailGroup?.filter(item => columnsToShowWithData ? Object.keys(columnsToShowWithData).includes(item.id) : false).map(item => item?.name) : [];

    const hasHashBoardsColumn = detailGroup ? detailGroup?.map(item => item?.name).includes("Hash Boards") : [];
    const hasNotificationsColumn = detailGroup ? detailGroup?.map(item => item?.name).includes("Notifications") : [];

    const pickUpTime = (value) => {
        let upTimeValue = "";
        const upTime = value ? value?.split(",")[2].substr(8) : "";
        const [hours, minutes, seconds] = upTime ? upTime.match(/\d+/g) : [];
        if (hours && minutes && seconds) {
            upTimeValue = `${hours}h ${minutes}m `;
        }
        return upTimeValue;
    }

    const detailPanelColumns = [
        ...columnsTitles,
        hasHashBoardsColumn ? "Hash Boards" : "",
        hasNotificationsColumn ? "Notifications" : "",
    ];


    const detailedInfoWithColumnNames = columnsToShowWithData ? (Object.entries(columnsToShowWithData).reduce((acc, [key, value]) => {
        const columnTitle = detailGroup ? detailGroup?.filter(item => item?.id === key)[0]?.name : "";
        acc[columnTitle] = value;
        return acc;
    }, {})) : {};

    const sortedDetailedInfoWithColumnNames = Object.keys(detailedInfoWithColumnNames).sort().reduce((acc, key) => { acc[key] = detailedInfoWithColumnNames[key]; return acc; }, {});



    return (
        <Box paddingLeft={"5.7rem"} overflow={"visible"}>
            <Grid container spacing={2}>
                {Object.entries(sortedDetailedInfoWithColumnNames).map(
                    ([key, value], index) => (
                        <Grid item xs={4} key={index}>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                                padding={"0.5rem 1.5rem 0.5rem 1rem"}
                                minHeight={"4.5rem"}
                            >
                                <BodyLarge>{key}</BodyLarge>
                                <BodyMedium>{value ? key?.toLowerCase() === "power efficiency" ? `${value} J/THs` : key?.toLowerCase() === "power" ? `${value} W` : key?.toLowerCase() === "uptime" ? pickUpTime(value) : key?.toLowerCase() === "hash rate" ? `${value} TH/s` : value : ""}</BodyMedium>
                            </Box>
                        </Grid>
                    )
                )}
                {detailPanelColumns?.includes("Hash Boards") && (
                    <Grid item xs={4}>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            padding={"0.5rem 1.5rem 0.5rem 1rem"}
                            alignItems={"center"}
                            minHeight={"4.5rem"}
                            flexGrow={1}
                            onClick={() => {
                                history.push("/hashboardheatmap");
                            }}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(29, 27, 32, 0.08)",
                                    borderRadius: "6.7rem",
                                },
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                                gap={4}
                            >
                                <GridOnIcon />
                                <BodyLarge> Hash Boards</BodyLarge>
                            </Box>
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <ArrowRightIcon />
                            </Box>
                        </Box>
                    </Grid>
                )}
                {detailPanelColumns?.includes("Notifications") && (
                    <Grid item xs={4}>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            minHeight={"4.5rem"}
                            padding={"0.5rem 1.5rem 0.5rem 1rem"}
                            flexGrow={1}
                            onClick={() => {
                                history.push("/notifications");
                            }}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(29, 27, 32, 0.08)",
                                    borderRadius: "6.7rem",
                                },
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                                gap={4}
                            >
                                <NotificationsActiveOutlinedIcon />
                                <BodyLarge> Notifications</BodyLarge>
                            </Box>
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <ArrowRightIcon />
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
export default function MinerPage() {
    const [leftSidePanelOpen, setLeftSidePanelOpen] = React.useState(false);
    const [rightSidePanelOpen, setRightSidePanelOpen] = React.useState(false);
    const [gridHeight, setGridHeight] = React.useState(0);
    const tableContainerRef = React.useRef(null);
    const history = useHistory();
    const location = useLocation();
    const { authToken } = useAppContextProvider();
    const { setTableGroup, setDetailGroup, tableGroup, detailGroup } = useMinerPageContext();
    // const [skip, setSkip] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(null);
    const limit = 25;
    //fetching to get userPreferences to set the tableGroup and detailGroup from user object. If not present, we are setting default values

    const {
        data: userInfo,
        isFetched: isUserInfoFetched,
        isFetching: isUserInfoFetching,
    } = useQuery({
        queryKey: ["userInfo", authToken],
        queryFn: () => GetUserUseQuery(authToken),
        enabled: !!authToken,
    });
    const user = userInfo?.user;

    // useEffect to check if any saved preference for column placement. If not present, set default values for initial users.
    useEffect(() => {
        if (isUserInfoFetched && user && user?.newPreference) {
            const newPreference = user?.newPreference;

            setTableGroup(newPreference?.tableGroup);
            setDetailGroup(newPreference?.detailGroup);
        } else if (isUserInfoFetched && user && !user?.newPreference) {
            const defaultValuesForTableGroupAndDetailGroup =
                returnDefaultColumnConfigs();

            const { tableGroup, detailGroup } =
                defaultValuesForTableGroupAndDetailGroup;
            setTableGroup(tableGroup);
            setDetailGroup(detailGroup);
        }
    }, [isUserInfoFetched, user]);



    /**
     * Fetches miner devices using an infinite query. enables pagination and fetches data in pages.Caches and retries on error.
     *
     * @returns {{
     *    data: any,
     *    fetchNextPage: () => void,
     *    isFetchingNextPage: boolean,
     *    status: string,
     *    isFetched: boolean
     * }}
     */
    const { data, fetchNextPage, isFetchingNextPage, status, isFetched } =
        useInfiniteQuery({
            queryKey: ["MinerDevices", authToken],
            queryFn: ({ pageParam = { skip: 0, limit } }) =>
                PostNewMinerDevice({
                    authToken,
                    body: { device: { ...pageParam } },
                }).then((res) => {
                    setTotalCount(res?.count);
                    return res;
                }),
            enabled: !!authToken,
            getNextPageParam: (lastPage, allPages) => {
                const totalFetched = allPages.flatMap(
                    (page) => page.docs
                ).length;

                // Stop fetching if the total fetched rows reach or exceed the total count
                if (totalFetched >= totalCount) {
                    return undefined;
                }

                // Return the skip value for the next page
                return { skip: allPages.length * limit, limit };
            },
        });
    const tableData = isFetched
        ? data.pages.flatMap((page, pageIndex) =>
            page.docs.map((item, index) => {
                // Generate a unique ID by combining the page index and the item index
                return { ...item, id: pageIndex * limit + index };
            })
        )
        : [];

    // Function to handle scroll end event and fetch next page    
    const handleScrollEnd = () => {
        if (!isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const columns = returnDefaultColumnConfigsForTable();
    const tableDetailColOrder = tableGroup.length > 0 ? tableGroup?.map((item) => item?.id) : [];
    // Create a new array of columns sorted by the order in tableDetailColOrder
    // Create a new array of columns sorted by the order in tableDetailColOrder
    const sortedColumns = tableDetailColOrder
        .map((id) => columns.find((column) => column?.field === id))
        .filter((column) => column !== undefined)
        .map((column) => ({ ...column, hide: false })); // Remove undefined columns here

    // Add the rest of the columns to the new array and set their hide property to true
    const restOfColumns = columns
        .filter((column) => !tableDetailColOrder.includes(column?.field))
        .map((column) => ({ ...column, hide: true })); // Ensure all non-included columns are hidden

    const finalColumns = [...sortedColumns, ...restOfColumns];
    const finalColumnsSetup = finalColumns.map((column) => {
        column?.headerName?.toLowerCase() === "hash boards" &&
            (column.renderCell = (params) => {
                const serialNo = (params?.row?.SerialNo);
                return <SecondaryButton endIcon={<ArrowRightIcon fontSize="small" />} disabled={!serialNo} onClick={(event, params) => {
                    history.push(`/hashboardheatmap?serialNo=${serialNo}`);
                }} >See all</SecondaryButton>
            })
        column?.headerName?.toLowerCase() === "notifications" &&
            (column.renderCell = (params) => {
                const serialNo = (params?.row?.SerialNo);
                return <SecondaryButton endIcon={<ArrowRightIcon fontSize="small" />} dsiabled={!serialNo} onClick={(event, params) => {
                    history.push(`/notifications?serialNo=${serialNo}`);
                }} >See all</SecondaryButton>
            })
    });

    // Construct column visibility model
    const columnVisibilityModel = finalColumns.reduce((acc, column) => {
        if (column?.field) { // Ensure column and field are defined
            acc[column.field] = !column.hide; // Set visibility based on the 'hide' property
        }
        return acc;
    }, {});




    const dataGridProps = {
        rows: Array.isArray(tableData) ? tableData : [],
        columns: [...finalColumns],
        page: 0,
        loading: isFetchingNextPage,
        paginationMode: "client",
        columnVisibilityModel: { ...columnVisibilityModel },
        getDetailPanelHeight: () => "auto",
        getDetailPanelContent: ({ row }) =>
            GetDetailPanelContent({ row, history, detailGroup }),
        getRowId: (row) => row.id,
        pagination: true,
        pageSize: tableData.length,
        disableColumnMenu: true,
        disableColumnResize: true,
        checkboxSelection: true,
        disableSelectionOnClick: true,
        disableCellSelection: true,
        onRowsScrollEnd: handleScrollEnd,
        loading: isFetchingNextPage,
        overlayNoRowsTemplate: "No data available",

        slots: {
            loadingOverlay: () => <LinearProgress />,
            detailPanelToggleCell: GridDetailPanelToggleCell,
            detailPanelExpandIcon: () => <ArrowDropDownIcon />,
            detailPanelCollapseIcon: () => <ArrowDropUp />,
        },
        slotProps: {
            detailPanelToggleCell: {
                detailPanelExpandIcon: <ArrowRight />,
                detailPanelCollapseIcon: <ArrowDropUp />,
            },
        },
        hideFooterPagination: true,
        hideFooter: true
    };

    //collecting the height of the table container so that the right side panel would have the same height as the table container.

    React.useEffect(() => {
        if (tableContainerRef.current) {
            setGridHeight(tableContainerRef?.current?.clientHeight);
        }
    }, [tableContainerRef]);

    return (
        //@ts-nocheck
        <Container
            component="div"
            maxWidth="100%"
            sx={{
                padding: "2rem 3.5rem !important ",
                margin: 0,
                backgroundColor: "#FAF8FF",
                overflowY: "auto",
                height: "100vh",
            }}
        >
            <HeaderContainer />
            <Grid
                container
                className={"miner-page-table-container"}
                display={"flex"}
                flexDirection={"row"}
                spacing={4}
                sx={{ transition: "all 0.3s ease-in-out" }}
            >
                <Grid
                    ref={tableContainerRef}
                    item
                    xs={rightSidePanelOpen ? 9 : 12}
                    sx={{
                        transition: "width 0.3s ease-in-out", // Smooth transition for width
                    }}
                >
                    <Box
                        className={"miner-page-table"}
                        display={"flex"}
                        flexDirection={"row"}
                        backgroundColor={"#F3F2FD"}
                        padding={"0.75rem 0  0.75rem 0"}
                        borderRadius={"1rem"}
                        sx={{
                            transition: "width 0.3s ease-in-out", // Smooth transition for width
                        }}
                    >
                        <LeftSidePanel
                            leftSidePanelOpen={leftSidePanelOpen}
                            setLeftSidePanelOpen={setLeftSidePanelOpen}
                            height={gridHeight}
                        />
                        <Box
                            className={"table-container"}
                            display={"flex"}
                            flexDirection={"column"}
                            padding={"0 0.75rem 0 0.75rem"}
                            height={"auto"}
                            minWidth={"0"}
                            min
                            gap={4}
                            flex={3}
                            flexShrink={1}
                        >
                            <MinerTableHeader
                                setRightSidePanelOpen={setRightSidePanelOpen}
                                rightSidePanelOpen={rightSidePanelOpen}
                                gridRef={tableContainerRef}
                            />
                            <MinerTableOperationsContainer
                                setRightSidePanelOpen={setRightSidePanelOpen}
                                gridRef={tableContainerRef}
                            />
                            <Box
                                className={"Datagrid-container"}
                                display={"flex"}
                                flexShrink={1}
                                flexGrow={1}
                            >
                                <DataGrid
                                    config={dataGridProps}
                                    height={"57vh"}
                                    maxHeight={"57vh"}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {rightSidePanelOpen && (
                    <Slide
                        direction="left"
                        in={rightSidePanelOpen}
                        timeout={350}
                    >
                        <Grid
                            item
                            xs={rightSidePanelOpen ? 3 : 0}
                            style={{
                                transition: "width 0.3s ease-in-out",
                                opacity: rightSidePanelOpen ? 1 : 0,
                            }}
                        >
                            <RightSidePanel
                                rightSidePanelOpen={rightSidePanelOpen}
                                setRightSidePanelOpen={setRightSidePanelOpen}
                                height={gridHeight}
                                gridRef={tableContainerRef}
                            />
                        </Grid>
                    </Slide>
                )}
            </Grid>
        </Container >
    );
}
