import React, { useState } from "react";
import { styled } from "@mui/material";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    backgroundColor: "none",

    borderWidth: "0px !important",
    borderStyle: "none !important",
    borderColor: "none !important", // Change root background color
  },
  "& .MuiDataGrid-cell": {
    color: "#44474E",
    fontWeight: 400,
    minHeight: "3rem",
    fontSize: "1rem",
    // padding: "0.25rem 1.5rem 0.25rem 1rem",
    fontFamily: "Roboto",
    lineHeight: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // borderBottom: "1px solid #C4C5D6",
    "&:focus, &:focus-within": {
      outline: "none",
    },
    // backgroundColor: "lightyellow", // Change cell background color
    // border: "1px solid green", // Change cell border
  },
  "& .MuiDataGrid-columnHeaders": {
    minHeight: "3rem",
    backgroundColor: "#F3F2FD",
    borderBottom: "none !important",
    "&:focus, &:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#F3F2FD",
    display: "flex",
    //  padding: "0.25rem 1.5rem 0.25rem 1rem",
    borderBottom: "none !important",
    "&:focus, &:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-row": {
    // borderBottom: "1px solid #ff5733 !important", // Set the row border color to your desired color
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 500,
    lineHeight: "1.5rem",
    fontSize: "1rem",
    fontFamily: "Roboto",
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    borderBottom: "unset !important",
    display: "flex",
    justifyContent: "flex-start !important",
    alignItems: "center",
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none", // Hide column separator
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none !important",
  },
  // "& .MuiDataGrid-selectedRowCount": {
  //   display: "none",
  // },
}));
