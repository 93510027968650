import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import {
  ClickAwayListener,
  createTheme,
  ThemeProvider,
  Typography,
  ButtonGroup,
  Button,
  Popover,
  Grid,
  FormControlLabel,
  Icon,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Popper } from "@mui/material";
import styled from "@emotion/styled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  H6HeadlineSmall,
  BodyLarge,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import {
  PrimaryButton,
  SecondaryButton,
  StyledIconButton,
} from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import StyledSwitch from "../../../../components/StyledComponents/Switch/Switch.js";
import { useForm, Controller } from "react-hook-form";
import CSVIcon from "../../../../assets/csv.svg";

const StyledPopoverContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #f3f2fd;
  border-radius: 1rem;

  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

const ProfileCardNameTypography = styled(Typography)({
  color: "#44474E",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "1.375rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.75rem",
});

const ProfileCardEmailTypography = styled(Typography)({
  color: "#44474E",
  textAlign: "center",
  fontFamily: "Roboto",
  position: "relative",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.25rem",
});

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 1rem; // Change this to the border radius you want
  }
`;

const StyledHeaderAndFooterBox = styled(Box)`
  padding: 1.5rem;
`;

const MoreOptionsCard = (props) => {
  const { open, handleClose, anchorRef } = props;
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      switchValue1: false,
      switchValue2: false,
      switchValue3: false,
      // Default value for the switch
    },
  });

  return (
    <>
      <StyledPopover
        sx={{ zIndex: 9999, borderRadius: "1rem" }}
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledPopoverContent>
            <StyledHeaderAndFooterBox
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <H6HeadlineSmall>Additional actions</H6HeadlineSmall>
            </StyledHeaderAndFooterBox>
            <form>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} flexDirection={"row"}>
                  <Box
                    className="left-container"
                    borderRight={"1px solid #CAC4D0"}
                  >
                    <Controller
                      name="switchValue1" // Field name
                      control={control} // Control from useForm
                      render={({ field }) => (
                        <Box
                          display="flex"
                          justifyContent={"space-between"}
                          minHeight={"3.5rem"}
                          alignItems="center" // Align items vertically in center
                          gap={4} // Gap between label and switch
                          padding={"0.5rem 1.5rem 0.5rem 1rem"} // Padding around the container
                          minWidth={"17.56rem"}
                        >
                          <BodyLarge>FluxVision tuning</BodyLarge>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                              <StyledSwitch
                                {...field} // Bind switch to react-hook-form
                                checked={field.value} // Handle checked state based on form value
                              />
                            }
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="switchValue2" // Field name
                      control={control} // Control from useForm
                      render={({ field }) => (
                        <Box
                          display="flex"
                          justifyContent={"space-between"}
                          alignItems="center" // Align items vertically in center
                          gap={4} // Gap between label and switch
                          padding={"0.5rem 1.5rem 0.5rem 1rem"}
                          minHeight={"3.5rem"} // Padding around the container
                          minWidth={"17.56rem"}
                        >
                          <BodyLarge>Standby mode</BodyLarge>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                              <StyledSwitch
                                {...field} // Bind switch to react-hook-form
                                checked={field.value} // Handle checked state based on form value
                              />
                            }
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="switchValue3" // Field name
                      control={control} // Control from useForm
                      render={({ field }) => (
                        <Box
                          display="flex"
                          justifyContent={"space-between"}
                          alignItems="center" // Align items vertically in center
                          gap={4} // Gap between label and switch
                          padding={"0.5rem 1.5rem 0.5rem 1rem"}
                          minHeight={"3.5rem"} // Padding around the container
                          minWidth={"17.56rem"}
                        >
                          <BodyLarge>Flash LED</BodyLarge>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                              <StyledSwitch
                                {...field} // Bind switch to react-hook-form
                                checked={field.value} // Handle checked state based on form value
                              />
                            }
                          />
                        </Box>
                      )}
                    />
                  </Box>
                  <Box className="right-container">
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center" // Align items vertically in center
                      gap={4} // Gap between label and switch
                      padding={"0.5rem 1.5rem 0.5rem 1rem"}
                      minHeight={"3.5rem"}
                      minWidth={"17.56rem"}
                    >
                      <BodyLarge>Notifications</BodyLarge>
                      <StyledIconButton>
                        <AddIcon fontSize={"small"} sx={{ color: "#000" }} />
                      </StyledIconButton>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center" // Align items vertically in center
                      gap={4} // Gap between label and switch
                      padding={"0.5rem 1.5rem 0.5rem 1rem"}
                      minHeight={"3.5rem"}
                      minWidth={"17.56rem"}
                    >
                      <BodyLarge>Upgrade</BodyLarge>
                      <StyledIconButton>
                        <UpgradeIcon
                          fontSize={"small"}
                          sx={{ color: "#000" }}
                        />
                      </StyledIconButton>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center" // Align items vertically in center
                      gap={4} // Gap between label and switch
                      padding={"0.5rem 1.5rem 0.5rem 1rem"}
                      minHeight={"3.5rem"}
                      minWidth={"17.56rem"}
                    >
                      <BodyLarge>Export CSV</BodyLarge>
                      <StyledIconButton>
                        <img src={CSVIcon} alt="CSV" />
                      </StyledIconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  padding={" 1.5rem 1.5rem 1.5rem 1rem"}
                  gap={2}
                >
                  <SecondaryButton> cancel</SecondaryButton>
                  <PrimaryButton> save</PrimaryButton>
                </Box>
              </Box>
            </form>
          </StyledPopoverContent>
        </ClickAwayListener>
      </StyledPopover>
    </>
  );
};

export const MinerOperationsTab = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          // paddingRight: "2rem",
        }}
      >
        <Tooltip title="More options">
          <StyledIconButton
            ref={anchorRef}
            onClick={(event) => handleClick(event)}
          >
            <MoreVertIcon fontSize={"medium"} sx={{ color: "#000000" }} />
          </StyledIconButton>
        </Tooltip>
      </Box>

      <MoreOptionsCard
        open={open}
        handleClose={handleClose}
        anchorRef={anchorRef}
      />
    </React.Fragment>
  );
};
