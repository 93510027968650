import React, { createContext, useCallback, useMemo, useState } from "react";
import {
    calculateTimeForLastSevenDays,
    calculateTimeForLastThirtyDays,
    calculateTimeToMidnight,
    calculateTimeForLastSixMonths,
    calculateTimeForYTD,
} from "./Utils/timeFunctions";

// Create the context
export const AppContext = createContext();

const timeFilterSelectOptions = [
    { label: "Today", value: "Today" },
    { label: "Last 7 days", value: "Last 7 days" },
    { label: "Last 30 days", value: "Last 30 days" },
    { label: "Last 6 months", value: "Last 6 months" },
    { label: "YTD", value: "YTD" },
];

const binSizeValues = {
    Today: "hour",
    "Last 7 days": "day",
    "Last 30 days": "day",
    "Last 6 months": "month",
    YTD: "month",
};

const AppContextProvider = (props) => {
    const [authToken, setAuthToken] = useState("");
    const [selectedValue, setSelectedValue] = useState("Last 7 days");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [topVal, setTopVal] = useState(10);
    const [fromDateTime, setFromDateTime] = useState("");
    const [toDateTime, setToDateTime] = useState("");
    const [timeZone, setTimeZone] = useState("");
    const [binSize, setBinSize] = useState("");
    const [utcTimeStartString, setUtcTimeStartString] = useState("");
    const [utcTimeEndString, setUtcTimeEndString] = useState("");
    const [utcTimeStartStringForDashboard, setUtcTimeStartStringForDashboard] =
        useState("");
    const [utcTimeEndStringForDashboard, SetUtcTimeEndStringForDashboard] =
        useState("");
    const timeFilterValues = useMemo(() => [...timeFilterSelectOptions], []);

    const clearDateTimeValues = () => {
        return {
            currentDateTimeMinusOneMinute: "",
            startOfTheDay: "",
            userTimeZone: "",
            utcTimeEndString: "",
            utcTimeStartString: "",
            utcTimeStartStringForDashboard: "",
            utcTimeEndStringForDashboard: "",
        };
    };

    const calculateDateTimeForSelection = (selection) => {
        switch (selection) {
            case "Today":
                return calculateTimeToMidnight();
            case "Last 7 days":
                return calculateTimeForLastSevenDays();
            case "Last 30 days":
                return calculateTimeForLastThirtyDays();
            case "Last 6 months":
                return calculateTimeForLastSixMonths();
            case "YTD":
                return calculateTimeForYTD();
            case "clearTimeFilter":
                return clearDateTimeValues();
            default:
                return calculateTimeToMidnight();
        }
    };

    const calculateDateTimeValues = useCallback(
        (selection) => {
            const {
                currentDateTimeMinusOneMinute,
                startOfTheDay,
                userTimeZone,
                utcTimeEndString,
                utcTimeStartString,
                utcTimeStartStringForDashboard,
                utcTimeEndStringForDashboard,
            } = calculateDateTimeForSelection(selection);
            setSelectedValue(selection === "clearTimeFilter" ? "" : selection);
            setFromDateTime(startOfTheDay);
            setToDateTime(currentDateTimeMinusOneMinute);
            setUtcTimeStartString(utcTimeStartString);
            setUtcTimeEndString(utcTimeEndString);
            setUtcTimeStartStringForDashboard(utcTimeStartStringForDashboard);
            SetUtcTimeEndStringForDashboard(utcTimeEndStringForDashboard);
            setTimeZone(userTimeZone);
            setBinSize(binSizeValues[selection]);
        },
        [calculateDateTimeForSelection]
    );

    const contextValue = useMemo(() => {
        return {
            topVal,
            setTopVal,
            toastOpen,
            setToastOpen,
            toastMessage,
            setToastMessage,
            selectedGroup,
            setSelectedGroup,
            authToken,
            timeFilterValues,
            selectedValue,
            fromDateTime,
            toDateTime,
            timeZone,
            binSize,
            utcTimeStartString,
            utcTimeEndString,
            utcTimeStartStringForDashboard,
            utcTimeEndStringForDashboard,
            setSelectedValue,
            setAuthToken,
            calculateDateTimeValues,
        };
    }, [
        topVal,
        setTopVal,
        authToken,
        selectedGroup,
        setSelectedGroup,
        binSize,
        calculateDateTimeValues,
        fromDateTime,
        selectedValue,
        timeFilterValues,
        timeZone,
        toDateTime,
        utcTimeEndString,
        utcTimeEndStringForDashboard,
        utcTimeStartString,
        utcTimeStartStringForDashboard,
    ]);

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

// Export the context
export default AppContextProvider;
