import styled from "@emotion/styled";
import { Dialog, DialogTitle } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  // backgroundColor: "#F3F2FD",
  boxShadow:
    "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)", // Adjust the border-radius value as needed

  "& .MuiPaper-root": {
    borderRadius: "1rem",
    backgroundColor: "#F3F2FD",
    padding: "1rem",
    overflowY: "hidden",
    overflowX: "hidden",
  },
}));
export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  "&.MuiDialogTitle-root": {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "0",
    paddingRight: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "8px",
    minWidth: "41.5rem",
    // Remove padding
  },
  root: {},
}));
