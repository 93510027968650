import { StyledDialog, StyledDialogTitle } from "../../../components/StyledComponents/Dialog/Dialog";
import { BodyMedium, H6HeadlineSmall } from "../../../components/StyledComponents/Typography/Typography.tsx";
import { useForm } from "react-hook-form";
import React from "react";
import { Box, DialogActions, DialogContent, FormControl, InputAdornment, InputLabel, Select, TextField } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MenuItem from "@mui/material/MenuItem";
import { PostUser } from "../../../api/api.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider";

const EditProfileDialog = ({ open, onClose, deleteAPIKey, refetch }) => {

    return (
        <StyledDialog maxWidth="sm" hideBackdrop open={open} onClose={onClose} sx={{ overflowX: "hidden" }} padding={"1.5rem"}>
            <StyledDialogTitle>
                <H6HeadlineSmall style={{ textAlign: "left" }}>
                    Delete API key ?
                </H6HeadlineSmall>
            </StyledDialogTitle>
            <DialogContent sx={{ padding: 0, overflowX: "hidden" }}>
                <Box display={"flex"} flexDirection={"column"} gap={6} marginTop={1} >
                    <BodyMedium> Deleting the API key invalidates access to any applications or services using it. This action cannot be undone, so proceed with caution. </BodyMedium>
                    <Box display={"flex"} flexDirection={"row"} gap={4} justifyContent={"flex-end"}>
                        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                        <PrimaryButton sx={{ padding: "1rem" }} onClick={() => {
                            deleteAPIKey();
                            refetch();
                            onClose();
                        }}>Delete API key</PrimaryButton>
                    </Box>
                </Box>
            </DialogContent>
        </StyledDialog>
    )
}

export default EditProfileDialog;