import React, { createContext, useMemo, useState } from "react";

export const MinerPageContext = createContext();

export const MinerPageContextProvider = (props) => {
    const [tableGroup, setTableGroup] = useState({});
    const [detailGroup, setDetailGroup] = useState({});
    const contextValue = useMemo(() => {
        return {
            tableGroup,
            setTableGroup,
            detailGroup,
            setDetailGroup,
        };
    }, [tableGroup, detailGroup]);

    return (
        <MinerPageContext.Provider value={contextValue}>
            {props.children}
        </MinerPageContext.Provider>
    );
};
