//@ts-nocheck
import React from "react";
import Box from "@mui/material/Box";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import { SecondaryButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import { MinerOperationsTab } from "../Operations/MoreOperations/MinerOperationTab";
import { GroupOperationsTab } from "../Operations/GroupOperations/GroupOperations";
import TagOperationsCard, {
  TagOperationsTab,
} from "./TagOperations/TagOperations";

export const MinerTableOperationsContainer = React.memo((props) => {
  const { setRightSidePanelOpen, rightSidePanelOpen } = props;
  return (
    <Box
      className={"table-operations-container"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
    >
      <Box className={"table-operations-container-left"}></Box>
      <Box
        className={"table-operations-container-right"}
        display={"flex"}
        flexDirection={"row"}
        gap={2}
      >
        <GroupOperationsTab />
        <TagOperationsTab />
        <MinerOperationsTab />
      </Box>
    </Box>
  );
});
