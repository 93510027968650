// @ts-nocheck
import React from "react";
import {
  Box,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from "@mui/material";
import { H9TitleSmall } from "../../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const PoolConfiguration = React.memo((props) => {
  const { poolConfigurationData } = props;
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="0.25rem 0.5rem 0.25rem 1rem"
        alignItems={"center"}
      >
        <H9TitleSmall> Pools </H9TitleSmall>
        <StyledIconButton>
          <KeyboardArrowRightIcon fontSize="medium" sx={{ color: "#000000" }} />
        </StyledIconButton>
      </Box>
      <>
        {poolConfigurationData?.length > 0 &&
          poolConfigurationData?.map((pool, index) => (
            <List
              key={index}
              sx={{
                width: "100%",
                backgroundColor: "#EEEDF7",
                borderRadius: "1.5rem",
              }}
            >
              <ListItem>
                <ListItemText>{`Pool ${index + 1} :`}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary="URL" secondary={pool.url} />
              </ListItem>
              <ListItem>
                <ListItemText primary="User" secondary={pool.user} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Pass" secondary={pool.pass} />
              </ListItem>
            </List>
          ))}
      </>
    </Box>
  );
});
