import { Box, Grid, Skeleton } from "@mui/material";
import { BodyLarge, H8TitleMedium, H9TitleSmall } from "../../../components/StyledComponents/Typography/Typography.tsx";
import React from "react";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import { env } from "../../../env.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Avatar from "@mui/material/Avatar";
import { userRolesMap } from "../../../constants.js"
import { UserProfileCardLoadingContainer } from "./UserProfileCardLoading.js"
import { postAPIKeyForUser } from "../../../api/api.js";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog";
import EditProfileDialog from "../EditProfileDIalog/EditProfileDialog";

const UserProfileCard = (props) => {
    const { userData, isFetched, isLoading, isFetching, refetch } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);


    return (
        <>
            {isFetched && <Grid
                container
                spacing={0}
                className={"content-container"} d
                padding={"2rem"}
                border={"1px solid #C4C5D6"}
                borderRadius={"1.5rem"}
            >
                <Grid item xs={5.75}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}
                        gap={6}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"flex-start"}
                        >
                            <H8TitleMedium>Basic info</H8TitleMedium>
                        </Box>
                        <Box>
                            <Grid container spacing={6}>
                                <Grid item xs={1}>
                                    <Avatar
                                        alt={`${userData?.user?.name}`}

                                        src="/static/images/avatar/1.jpg"
                                        sx={{ width: 40, height: 40, bgcolor: "#2D55C9" }}
                                    >{userData?.user?.name.substring(0, 1)} </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"flex-start"}
                                        gap={4}
                                    >
                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                            <Grid item xs={2} > <H9TitleSmall>Name</H9TitleSmall> </Grid>
                                            <Grid item xs={10} ><BodyLarge>{userData?.user?.name}</BodyLarge> </Grid>
                                        </Grid>
                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                            <Grid item xs={2}> <H9TitleSmall>Role</H9TitleSmall> </Grid>
                                            <Grid item xs={10}> <BodyLarge>{userRolesMap[userData?.user?.role]}</BodyLarge></Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box >
                </Grid >
                <Grid item xs={5.75}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}
                        gap={6}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"flex-start"}
                        >
                            <H8TitleMedium>Contact info</H8TitleMedium>
                        </Box>
                        <Box>
                            <Grid container >
                                <Grid item xs={12}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"flex-start"}
                                        gap={4}
                                        flexGrow={1}
                                    >
                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} flexGrow={1} width={"100%"}>
                                            <Grid item xs={2}>
                                                <H9TitleSmall>Address</H9TitleSmall>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <BodyLarge>{userData?.user?.address ? userData?.user?.address : "N/A"}</BodyLarge>
                                            </Grid>
                                        </Grid>
                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                            <Grid item xs={2}>
                                                <H9TitleSmall>Phone</H9TitleSmall>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <BodyLarge>{userData?.user?.phone ? userData?.user?.phone : "N/A"}</BodyLarge>
                                            </Grid>
                                        </Grid>
                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                            <Grid item xs={2}>
                                                <H9TitleSmall>Email</H9TitleSmall>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <BodyLarge>{userData?.user?.email}</BodyLarge>
                                            </Grid>
                                            {userData?.user?.invite === "accepted" && (
                                                <Grid item xs={2}>
                                                    <Box display={"flex"} justifyContent={"flex-end"}>
                                                        <span className="material-symbols-outlined" style={{ color: "#24A64A" }}>check_circle</span>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={0.5}>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                        <StyledIconButton onClick={() => setDialogOpen(true)}>
                            <span className="material-symbols-outlined" style={{ color: "#000" }}>edit</span>
                        </StyledIconButton>
                    </Box>
                </Grid>
                <Grid>
                    <EditProfileDialog open={dialogOpen} onClose={() => setDialogOpen(false)} user={userData?.user} refetch={refetch} />

                </Grid>
            </Grid >}
            {(isLoading) && <UserProfileCardLoadingContainer />}
        </>
    );
}

export default UserProfileCard;