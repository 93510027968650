import React from "react";
import HashboardHeatMap from "./HashboardHeatMap";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const HashboardHeatMapPage = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { retry: 0 },
        },
    });
    return (
        <QueryClientProvider client={queryClient}>
            <HashboardHeatMap />
        </QueryClientProvider>
    );
}

export default HashboardHeatMapPage;