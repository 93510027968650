// @ts-nocheck
import React from "react";
import { Box, Slide } from "@mui/material";
import { H7TitleLarge } from "../../../components/StyledComponents/Typography/Typography.tsx";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import CloseIcon from "@mui/icons-material/Close";
import {
  PoolConfiguration,
  poolConfiguration,
} from "./PoolConfiguration/PoolConfiguration";
import { PriceProfile } from "./priceProfile/priceProfile";
import { SiteDemand } from "./SiteDemand/SiteDemand";
import { AggregatedTHs } from "./AggregatedTHs/AggregatedTHs";
import { AggregatedPower } from "./AggregatedPower/AggregatedPower";
import { MinersHashing } from "./MinersHashing/MinersHashing";
const poolCOnfiguration = [
  {
    dgname: "FrankTest2",
    priceConfigName: "FrankTest2",
    demandResponseConfigName: "FrankTest2",
    priceConfig: {
      ISO: "ERCOT",
      PriceZone: "HB_HOUSTON",
      PPAType: "Index",
      TunePreference: "Manual",
      EnergyPrice: 17.44,
      HashPrice: 53.7,
      updatedAt: "2024-09-12T20:21:43.214Z",
    },
    demandResponseConfig: {
      QSE: "Voltus",
      VoltusOptIn: true,
      rampupdown_time: 139,
      updatedAt: "2024-08-23T21:44:20.770Z",
      customer_location_id: "111",
      site_id: "d4z9",
    },
    tuneConfig: {
      PowerConstraintMax: -1,
      PowerConstraintMin: -1,
      PowerOperationMode: false,
      TargetHashRate: 100,
      TuneOperationMode: false,
      energyPrice: 0.12,
      priceConfig: "FrankTest2",
      demandResponseConfig: "FrankTest2",
    },
    config: {
      ztp: {
        ztp: {
          enabled: true,
          interval: 60,
        },
        ws: {
          enabled: true,
          interval: 60,
        },
        summary: {
          enabled: true,
          interval: 60,
        },
        mode: {
          enabled: true,
          interval: 60,
        },
        led: {
          enabled: true,
          interval: 60,
        },
        ipreport: {
          enabled: true,
          interval: 120,
        },
        gettimedate: {
          enabled: true,
          interval: 120,
        },
        telemetry: {
          enabled: true,
          interval: 180,
        },
        pools: {
          enabled: true,
          interval: 300,
        },
        stats: {
          enabled: true,
          interval: 300,
        },
        lcd: {
          enabled: true,
          interval: 300,
        },
        fan: {
          enabled: true,
          interval: 300,
        },
        temperature: {
          enabled: true,
          interval: 300,
        },
        frequency: {
          enabled: true,
          interval: 300,
        },
        voltage: {
          enabled: true,
          interval: 300,
        },
        psu: {
          enabled: true,
          interval: 300,
        },
        version: {
          enabled: true,
          interval: 3600,
        },
        config: {
          enabled: true,
          interval: 3600,
        },
        devs: {
          enabled: true,
          interval: 3600,
        },
        coin: {
          enabled: true,
          interval: 3600,
        },
        devdetails: {
          enabled: true,
          interval: 3600,
        },
        poolCfg: [
          {
            url: "stratum+tcp://btc.f2pool.com:3333",
            user: "MiningRobot.ZTPUser111111",
            pass: "1234567",
          },
          {
            url: "stratum+tcp://btc.f2pool.com:3333",
            user: "MiningRobot.ZTPUser222222",
            pass: "123",
          },
          {
            url: "stratum+tcp://btc.f2pool.com:3333",
            user: "MiningRobot.ZTPUser33",
            pass: "abc123",
          },
        ],
        updatedAt: "2024-09-09T20:42:12.341Z",
      },
    },
    count: 8,
    totalHashingDevicesLastHour: 2,
    aggregatedTHsLastHour: 192.02227815924516,
    aggregatedJperTHsLastHour: 23.24730256714265,
    aggregatedPowerConsumptionLastHour: 4464,
  },
];

export const RightSidePanel = React.memo((props) => {
  const { rightSidePanelOpen, setRightSidePanelOpen, height, gridRef } = props;
  console.log("gridRef", gridRef);

  const data = poolCOnfiguration[0];
  const priceConfig = {
    ...data.priceConfig,
    configName: data.priceConfigName,
    hashrate: data.aggregatedTHsLastHour,
  };

  const demandResponseConfig = {
    ...data.demandResponseConfig,
    configName: data.demandResponseConfigName,
  };
  console.log(demandResponseConfig);

  const aggregatedTHs = { aggregatedTHsLastHour: data.aggregatedTHsLastHour };

  const aggregatedPowerConsumption = {
    aggregatedPowerConsumptionLastHour: data.aggregatedPowerConsumptionLastHour,
  };

  const minerHashingData = {
    totalHashingDevicesLastHour: data.totalHashingDevicesLastHour,
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={4}
      width={rightSidePanelOpen ? "100%" : "0"} // Control width for transition
      flex={rightSidePanelOpen ? 1 : 0} // Control flex for smoother adjustment
      sx={{
        backgroundColor: "#F3F2FD",
        padding: rightSidePanelOpen ? "0.75rem" : "0", // Adjust padding for closed state
        borderRadius: "1rem", // Hide overflow to avoid content showing while closing
      }}
      flexGrow={1}
    >
      {rightSidePanelOpen && (
        <Box
          display="flex"
          flexDirection={"column"}
          flexGrow={1}
          height={height}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            sx={{ top: 0, position: "sticky", zIndex: 1 }}
            flexShrink={0}
          >
            <H7TitleLarge>Site Configurations</H7TitleLarge>
            <StyledIconButton
              onClick={() => {
                setRightSidePanelOpen(false);
                if (gridRef.current) {
                  gridRef.current = null;
                }
              }}
            >
              <CloseIcon fontSize={"medium"} sx={{ color: "#000000" }} />
            </StyledIconButton>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            sx={{
              flexGrow: 1, // Allow content to grow and fill remaining space
              overflow: "auto", // Apply scrolling only to this box
              maxHeight: height * 0.95,
              // Adjust max height if necessary
            }}
          >
            <PoolConfiguration
              poolConfigurationData={data?.config?.ztp?.poolCfg}
            />
            <PriceProfile priceProfileData={priceConfig} />
            <SiteDemand siteDemandData={demandResponseConfig} />
            <AggregatedTHs hashRateData={aggregatedTHs} />
            <AggregatedPower powerData={aggregatedPowerConsumption} />
            <MinersHashing minerHashingData={minerHashingData} />
          </Box>
        </Box>
      )}
    </Box>
  );
});
