import React from "react";
import { StyledButton } from "../StyledComponents/Buttons/AuradineButtons";
import { LabelLarge } from "../StyledComponents/Typography/Typography.tsx";
import CloseIcon from "@mui/icons-material/Close";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const PlaceHolderButton = (props) => {
  const { text, onClose } = props;
  return (
    <StyledButton>
      <LabelLarge sx={{ color: "#303C67" }} noWrap>
        {text}
      </LabelLarge>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" sx={{ color: "#303C67" }} />
      </IconButton>
    </StyledButton>
  );
};

export default PlaceHolderButton;
