//@ts-nocheck
import React from "react";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import CheckIcon from "../../../assets/check_icon.svg";
import CloseIcon from "../../../assets/close_icon.svg";

const closeIconSVG = `data:image/svg+xml;base64,${btoa(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="white"/>
  </svg>
`)}`;

const DoneIconSVG = `data:image/svg+xml;base64,${btoa(`
<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none">
<path d="M6.0001 10.78L3.2201 8.00002L2.27344 8.94002L6.0001 12.6667L14.0001 4.66668L13.0601 3.72668L6.0001 10.78Z" fill="#000A32"/>
</svg>
`)}`;

const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "3.25rem",
  height: "2rem",
  border: "2px solid #747685",
  borderRadius: "6.25rem",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginLeft: 1,
    marginTop: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(21px)", // Moves the thumb to the right
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff", // Keep the thumb white
        backgroundImage: `url(${CheckIcon})`, // Display the "done" icon when checked
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#2D55C9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      // Optional: add styles for focus state
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "#747685",
    backgroundImage: `url(${CloseIcon})`, // Initial icon (closed state)
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 300ms ease-in-out", // Smooth transition for the icon
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E2E1EC",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default StyledSwitch;
