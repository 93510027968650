import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Container, IconButton, Toolbar, styled } from "@mui/material";
import { goodTime } from "../components/util";
import InviteDialogNew from "./invite-dialog.js";
import OrgUserMenu from "./orgUserMenu";
import * as api from "../api/api";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
// import { DashboardTitleTypography } from "../dashboard/dashboard";
import DataGrid from "../components/DataGrid/DataGrid.tsx";
import { H4HeadlineLarge, H6HeadlineSmall, BodyLarge } from "../components/StyledComponents/Typography/Typography.tsx";
import { format, parseISO } from "date-fns";

const OrganizationDetailsLabel = Styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1rem",
});

const OrganizationDetailsValue = Styled(Typography)({
  color: "#000",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
});

export const HeaderContainer = React.memo((props) => {
  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="flex-start"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H6HeadlineSmall>Administrators</H6HeadlineSmall>
        <BodyLarge>
          View and manage the list of administrators. You can invite new members, and adjust roles for those who have access to the FluxVision management console.
        </BodyLarge>
      </Box>
    </Box>
  );
});

let columns = [
  { field: "name", headerName: "User name", minWidth: 150, flex: 1 },
  { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
  //{ field: "team", headerName: "TEAM", minWidth: 150, flex: 1 },
  { field: "role", headerName: "Role", minWidth: 150, flex: 1 },
  //{ field: "invite", headerName: "Invited", minWidth: 150, flex: 1 },
  {
    field: "invitedAt",
    headerName: "Invited",
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      console.log(params);
      return params ? format(parseISO(params), "MM/dd/yyyy HH:MM:SS ") : null;
    },
  },
  {
    field: "createdAt",
    headerName: "Joined",
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => (params ? format(parseISO(params), "MM/dd/yyyy HH:MM:SS ") : null),
  },
];

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log("status is", Status);
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Aurascape";
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "unauth":
      h4Msg = "Authentication Failed!";
      pMsg = "User is not authorized to perform this operation";
      validUser = 0;
      break;
    case "error":
      h4Msg = "Failed!";
      pMsg = "Failed to update the Org name";
      validUser = 0;
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Aurascape";
      break;
  }
  if (Status !== "ok") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

let validUser = -1;

var UserA = {};

export const OrganizationPage = () => {
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);

  const [org, setOrg] = useState({
    org_id: "",
    name: "",
    member: [],
    owner: "",
  });

  const { getAccessTokenSilently } = useAuth0();

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUser(token, (data) => {
        if (data.status === "error") {
          if (data.errorCode === 1007) {
            validUser = 1;
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          } else {
            validUser = 1;
            setSubmitMsg("fetchfailed");
            setSuccessModalOpen(true);
          }
        } else {
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
          validUser = 0;
          UserA = data;
        }
      });
      api.GetOrg(token, (data) => {
        setOrg(data);
      });
    };
    fn();
  }, [edit, state]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const tname = target.name;

    setOrg((prevState) => ({
      ...prevState,
      [tname]: value,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const fn = async () => {
      const token = await getAccessTokenSilently();
      const data = await api.PostOrg(token, { name: org.name });
      if (data.hasOwnProperty("errorCode") && data.errorCode === 1009) {
        validUser = 1;
        setSubmitMsg("unauth");
        setSuccessModalOpen(true);
      } else if (data.status === "error") {
        validUser = 1;
        setSubmitMsg("error");
        setSuccessModalOpen(true);
      } else {
        //validUser = 0
        setEdit(false);
      }
    };
    fn();
  };
  const { user: tableRows } = org;
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  const dataGridProps = {
    rows: Array.isArray(tableRows) ? tableRows : [],
    columns: [
      ...columns,
      {
        field: "action",
        headerName: "",
        sortable: false,

        align: "left",
        width: 50,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <OrgUserMenu user={UserA} item={params.row} setState={setState} />
          );
        },
      },
    ],
    pagination: true,
    pageSize,
    page: 0,
    paginationMode: "client",
    rowCount: Array.isArray(tableRows) ? tableRows.length : 0,
    onPageSizeChange: handlePageSizeChange,
    rowsPerPageOptions: [25, 50, 100],
    getRowId: (row) => row.email,
    disableColumnMenu: true,
    disableColumnResize: true,
    checkboxSelection: false,
    disableSelectionOnClick: true,
    disableCellSelection: true,
  };
  if (validUser === 0) {
    return (
      <Container
        component="div"
        display="flex"
        flexdirection="column"
        gap={6}
        maxWidth="100%"
        sx={{
          padding: "2rem 3.5rem !important ",
          margin: 0,
          backgroundColor: "#FAF8FF",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        <Box
          className={"header-container"}
          display={"flex"}
          justifyContent="flex-start"
          flexDirection="column"
          gap={4}
          marginBottom={"1.5rem"}
        >
          <HeaderContainer />
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexGrow={3}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box
                display="flex"
                flexDirection="row"
                gap={4}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <OrganizationDetailsLabel>
                  Organization ID
                </OrganizationDetailsLabel>
                <OrganizationDetailsValue>
                  {org?.org_id}
                </OrganizationDetailsValue>
              </Box>
            </Box>
            <InviteDialogNew setState={setState} />
          </Box>
        </Box>
        <Box sx={{ margin: 5 }} />
        <Box>
          <DataGrid config={dataGridProps} />
        </Box>

      </Container>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <div>
        <SuccessModal
          isOpen={successModalOpen}
          onClose={handleCloseSuccessModal}
          Status={submitMsg}
        />
      </div>
    );
  }
};

export default OrganizationPage;

