/**
 * UserAPICard component displays the user's API key information and provides functionality to generate, delete, and download API keys.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.userData - The user data.
 * @param {boolean} props.isFetched - Indicates whether the user data has been fetched.
 * @param {boolean} props.isLoading - Indicates whether the user data is currently being loaded.
 * @param {Function} props.refetch - Function to refetch the user data.
 * @returns {JSX.Element} The UserAPICard component.
 */
import { Box, Grid, Skeleton } from "@mui/material";
import { BodyLarge, H8TitleMedium, H9TitleSmall } from "../../../components/StyledComponents/Typography/Typography.tsx";
import React from "react";
import { SecondaryButton, StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import { env } from "../../../env.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider";
import { QueryClient, useQuery } from "@tanstack/react-query";
import Avatar from "@mui/material/Avatar";
import { userRolesMap } from "../../../constants.js"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PostUserWithAPIKeyNew } from "../../../api/api.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { encryptText, decryptText, generateKey, exportKeyToBase64, importKeyFromBase64 } from '../../utils/utils.js';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { formatInTimeZone } from "date-fns-tz";
import { format, parseISO } from "date-fns";
import UserAPICardLoading from "./UserAPICardLoading.js";
import DeleteAPIDialog from "../DeleteAPIDialog/DeleteAPIDialog.js";


const UserAPICard = (props) => {
    const { userData, isFetched, isLoading, refetch } = props;

    const { setToastMessage, setToastOpen, authToken } = useAppContextProvider();
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const {
        data: api_key,
        isLoading: isLoadingAPIKey,
        isFetching: isFetchingAPIKey,
        isError: isErrorAPIKey,
        isFetched: isFetchedAPIKey,
        refetch: refetchAPIKey
    } = useQuery({
        queryKey: ["userAPI", authToken],
        queryFn: async ({ queryKey }) => {
            const [, authToken] = queryKey;
            const response = await fetch(env.APIPath.getUserAPIKey, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        },
        enabled: !!authToken,
    });

    const mutation = useMutation({
        mutationFn: ({ token, user }) => PostUserWithAPIKeyNew(token, user),
        onSuccess: (data, variables) => {
            if (data?.status?.toLowerCase() === "error") {
                setToastOpen(true);
                setToastMessage("There was an issue with API key configuration");
            } else if (data?.status?.toLowerCase() === "ok" && !variables?.user) {
                setToastOpen(true);
                setToastMessage("Your API key has been deleted.");
            } else if (data?.status.toLowerCase() === "ok" && variables?.user) {
                setToastOpen(true);
                setToastMessage("Your API key has been generated.");
            }
            queryClient.invalidateQueries({ queryKey: ['user'], refetchType: 'active' });
            refetchAPIKey();
        },
        onError: () => {
            setToastOpen(true);
            setToastMessage("There was an issue with API key configuration");
        }
    });


    const generateRandomAPIKey = (length = 64) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let apiKey = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            apiKey += characters[randomIndex];
        }
        return apiKey;
    };


    const decryptAndDownloadKey = async () => {
        const { data } = await refetchAPIKey();

        if (data?.user?.api_key_new) {
            const { iv, content, base64 } = data?.user?.api_key_new;
            console.log("en", data);
            const base64key = await importKeyFromBase64(base64);
            const decryptedKey = await decryptText(content, iv, base64key);
            // Create CSV data
            const csvData = `API key\n${decryptedKey}`;

            // Create a Blob from the CSV data
            const blob = new Blob([csvData], { type: 'text/csv' });

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'api_key.csv';

            // Append the anchor to the body
            document.body.appendChild(a);

            // Trigger the download
            a.click();
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        }
    }

    const handleGenerateAPIKey = async () => {
        const key = await generateKey();
        const apiKey = generateRandomAPIKey();
        const { iv, content } = await encryptText(apiKey, key);
        const encryptedData = { iv, content };
        const base64Key = await exportKeyToBase64(key);
        const timeOfCreation = new Date();
        const data = { encryptedData, base64Key, timeOfCreation };
        mutation.mutate({ token: authToken, user: data });
    };

    const deleteAPIKey = async () => {
        mutation.mutate({ token: authToken, user: {} });
    }

    console.log("api_key", api_key);

    return (
        <>
            {isFetchedAPIKey && <Box
                className={"content-container"} d
                padding={"2rem"}
                border={"1px solid #C4C5D6"}
                borderRadius={"1.5rem"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
            >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} >
                    <H8TitleMedium>API Key</H8TitleMedium>
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} height={"3rem"} >
                        {api_key?.user?.api_key_new_created ? <BodyLarge>{`created on ${api_key.user?.api_key_new?.createdAt ? format(parseISO(api_key?.user?.api_key_new?.createdAt), "MM/dd/yyyy ") : ""}`}</BodyLarge> : <BodyLarge>No Existing API Key</BodyLarge>}
                    </Box>
                    {api_key?.user?.api_key_new_created ? <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <SecondaryButton endIcon={<DeleteOutlineOutlinedIcon />} onClick={() => setDialogOpen(true)}>Delete</SecondaryButton>
                        <SecondaryButton endIcon={<FileDownloadOutlinedIcon />} onClick={decryptAndDownloadKey}>Download API key</SecondaryButton>
                    </Box> : <SecondaryButton onClick={handleGenerateAPIKey}>Generate New API Key</SecondaryButton>}
                </Box>
                <DeleteAPIDialog open={dialogOpen} onClose={() => setDialogOpen(false)} deleteAPIKey={deleteAPIKey} refetch={refetch} />
            </Box>
            }
            {isLoading && <UserAPICardLoading />}
        </>
    );
}

export default UserAPICard;