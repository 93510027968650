import React from "react";
import { Box, Container, Grid, Skeleton } from "@mui/material";
import {
  BodyLarge,
  H7TitleLarge,
} from "../components/StyledComponents/Typography/Typography.tsx";
import UserProfileCard from "./components/UserProfileCard/UserProfileCard.js";
import UserAPICard from "./components/UserAPICard/UserAPICard.js";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";
import { useQuery } from "@tanstack/react-query";
import { env } from "../env.js";
import UserSubscriptionCard from "./components/UserSubscriptionCard/UserSubscriptionCard.js";


const User = () => {
  const { authToken } = useAppContextProvider();
  const {
    data: userData,
    isLoading,
    isFetching,
    isError,
    isFetched,
    refetch
  } = useQuery({
    queryKey: ["user", authToken],
    queryFn: async ({ queryKey }) => {
      const [, authToken] = queryKey;
      const response = await fetch(env.APIPath.user, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    enabled: !!authToken,
  });

  return (
    <Container
      component="div"
      display="flex"
      flexdirection="column"
      gap={6}
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <Box
        className={"header-container"}
        display={"flex"}
        justifyContent="flex-start"
        flexDirection="column"
        gap={4}
        marginBottom={"1.5rem"}
      >
        <Box
          className={"title-box"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={3.5}
        >
          <H7TitleLarge>Profile</H7TitleLarge>
          <BodyLarge>
            Manage your profile and API key here. Update your information,
            generate a new API key, and adjust subscription preferences to keep
            your details up-to-date.
          </BodyLarge>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={6}>
        <UserProfileCard userData={userData} isLoading={isLoading} isFetched={isFetched} isFetching={isFetching} refetch={refetch} />
        <UserAPICard userData={userData} isLoading={isLoading} isFetched={isFetched} isFetching={isFetching} />
        <UserSubscriptionCard userData={userData} isLoading={isLoading} isFetched={isFetched} isFetching={isFetching} refetch={refetch} />
      </Box>
    </Container >
  );
};
export default User;
