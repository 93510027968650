import React, { useState, createContext, useMemo } from "react";

export const NavigationContext = createContext();

const Navigationcontextprovider = (props) => {
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(-1);
  const [menuSelectedValue, setMenuSelectedValue] = useState(null);

  const contextValue = useMemo(() => {
    return {
      selectedRouteIndex,
      setSelectedRouteIndex,
      menuSelectedValue,
      setMenuSelectedValue,
    };
  }, [menuSelectedValue, selectedRouteIndex]);
  return (
    <NavigationContext.Provider value={contextValue}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default Navigationcontextprovider;
