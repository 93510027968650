import { BodyLarge } from "../../components/StyledComponents/Typography/Typography.tsx";
import { DetailGroup, TableGroup, tableColumnsMapping } from "../../constants";
export const returnDefaultColumnConfigs = () => {
    return {
        tableGroup: TableGroup,
        detailGroup: DetailGroup,
    };
};



export const returnDefaultColumnConfigsForTable = () => {
    const renderCellFunctions = {
        "UpTime": (params) => {
            let upTimeValue = "";
            const upTime = params?.value ? params?.value.split(",")[2].substr(8) : "";
            const [hours, minutes, seconds] = upTime ? upTime.match(/\d+/g) : [];
            if (hours && minutes && seconds) {
                upTimeValue = `${hours}h ${minutes}m `;
            }
            return <BodyLarge>{upTimeValue}</BodyLarge>;
        },
        "Status": (params) => {
            return <BodyLarge>{params?.value}</BodyLarge>;
        },
        "THs": (params) => {
            return <BodyLarge>{params?.value ? `${params.value} TH/s` : ""}</BodyLarge>;
        },
        "Power": (params) => {
            return <BodyLarge>{params?.value ? `${params.value} W` : ""}</BodyLarge>;
        },
        "JTHs": (params) => {
            return <BodyLarge>{params?.value ? `${params.value} J/THs` : ""}</BodyLarge>;
        },


    }
    return Object.entries(tableColumnsMapping).map(([headerName, field]) => {
        return {
            field: field,
            headerName: headerName,
            minWidth: 106,
            flex: 1,
            renderCell: renderCellFunctions[field] ? renderCellFunctions[field] : (params) => { return <BodyLarge>{params?.value}</BodyLarge> },
        }
    });
};
