import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../components/StyledComponents/Dialog/Dialog"; // Import the StyledDialog and StyledDialogTitle components from the Dialog.js file in the StyledComponents/Dialog folder

import React from "react";
import {
  DialogContent,
  DialogActions,
  Autocomplete,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons";
import {
  BodyMedium,
  H8TitleMedium,
  H6HeadlineSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import { useForm, Controller } from "react-hook-form";
import PlaceHolderButton from "../../../components/PlaceHolderButton/PlaceHolderButton.js";

const NotificationsDialog = ({ open, handleClose }) => {
  const options = [
    "test@gmail.com",
    "test2@gmail.com",
    "test3@gmail.com",
    "test4@gmail.com",
    "test5@gmail.com",
    "test6@gmail.com",
    "test7@gmail.com",
    "test8@gmail.com",
    "test9@gmail.com",
    "sairam.jalakamdevarajulu@gmail.com",
    "sairam.jalakamdevarajulu@gmail.comsairamsairamsairam",
  ];

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      multiSelect: [],
    },
  });

  const selectedOptions = watch("autocomplete") || []; // Watch selected options in the form state

  const onSubmit = (data) => {
    console.log(data); // Handle form submission
  };

  const handleChipDelete = (optionToDelete) => {
    const newValue = selectedOptions.filter(
      (option) => option !== optionToDelete
    );

    setValue("autocomplete", newValue); // Update the state in react-hook-form
  };
  return (
    <StyledDialog open={open} onClose={handleClose} hideBackdrop={true}>
      <StyledDialogTitle>
        <H6HeadlineSmall style={{ textAlign: "left" }}>
          Add notification
        </H6HeadlineSmall>
        <BodyMedium style={{ textAlign: "left" }}>
          {" "}
          Select the users and what events you want them to be notified for.{" "}
        </BodyMedium>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" gap={6} flexGrow={1}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"flex-start"}
              gap={2}
              flexGrow={1}
            >
              <H8TitleMedium>Select whom to notify </H8TitleMedium>
              <Controller
                name="autocomplete"
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value, ref } }) => (
                  <Autocomplete
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    onChange={(_, data) => onChange(data)}
                    value={value || []}
                    clearIcon={null}
                    sx={{ maxWidth: "19rem", minWidth: "19rem" }}
                    options={options} // Replace with your options
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Email"
                        variant="outlined"
                        inputRef={ref}
                      />
                    )}
                    renderTags={() => null}
                  />
                )}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                maxHeight={"10.625rem"}
                maxWidth={"20rem"}
                overflow={"auto"}
              >
                {selectedOptions?.map((email, index) => (
                  <PlaceHolderButton
                    key={index}
                    onClose={() => handleChipDelete(email)}
                    text={email}
                  />
                ))}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"flex-start"}
              gap={2}
              flexGrow={1}
            >
              <H8TitleMedium>Select events</H8TitleMedium>
              <Controller
                name="Hash Board malfunction"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      minHeight: "3.5rem",
                      maxWidth: "20rem",
                      minWidth: "20rem",
                    }}
                    control={<Checkbox {...field} />}
                    label="Hash Board malfunction"
                  />
                )}
              />
              <Controller
                name="Fan malfunction"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      minHeight: "3.5rem",
                      maxWidth: "20rem",
                      minWidth: "20rem",
                    }}
                    control={<Checkbox {...field} />}
                    label="Fan malfunction"
                  />
                )}
              />
              <Controller
                name="PSU malfunction"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      minHeight: "3.5rem",
                      maxWidth: "20rem",
                      minWidth: "20rem",
                    }}
                    control={<Checkbox {...field} />}
                    label="PSU malfunction"
                  />
                )}
              />
              <Controller
                name="Chip temperature"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      minHeight: "3.5rem",
                      maxWidth: "20rem",
                      minWidth: "20rem",
                    }}
                    control={<Checkbox {...field} />}
                    label="Chip temperature"
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            marginTop={6}
            gap={2}
          >
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <PrimaryButton onClick={handleClose}>Save</PrimaryButton>
          </Box>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

export default NotificationsDialog;
