import { useAuth0 } from "@auth0/auth0-react";
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import * as api from '../api/api'

export default function OrgUserMenu({ user, item, setState }) {
    //console.log(user)

    const { getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const onMakeAdmin = async (event) => {
        const fn = async () => {
            const token = await getAccessTokenSilently();
            console.log('Make Pool Admin for user: ', item.email)
            await api.MakeAdminOtherUser(token, {
                email: item.email,
                role: 'admin'
            });
            setState((prevState) => !prevState)
            handleClose(event);
        }
        fn()
    }

    const onMakePoolAdmin = async (event) => {
        const fn = async () => {
            const token = await getAccessTokenSilently();
            console.log('Make Pool Admin for user: ', item.email)
            await api.MakeAdminOtherUser(token, {
                email: item.email,
                role: 'pooladmin'
            });
            setState((prevState) => !prevState)
            handleClose(event);
        }
        fn()
    }

    const onMakeReadOnly = async (event) => {
        const fn = async () => {
            const token = await getAccessTokenSilently();
            console.log('Make Pool Admin for user: ', item.email)
            await api.MakeAdminOtherUser(token, {
                email: item.email,
                role: 'readonly'
            });
            setState((prevState) => !prevState)
            handleClose(event);
        }
        fn()
    }

    const onRemove = async (event) => {
        const fn = async () => {
            const token = await getAccessTokenSilently();
            await api.DeleteOtherUser(token, {
                email: item.email
            });
            setState((prevState) => !prevState)
            handleClose(event);
        }
        fn()
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <IconButton
                color="primary"
                component="span"
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreVertOutlinedIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="left-start"
                disablePortal
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>

                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    {(user && (user.role === "superadmin") && (user.email !== item.email))
                                        ? <MenuItem onClick={onMakePoolAdmin}>Make Pool Admin</MenuItem>
                                        : <MenuItem disabled onClick={onMakePoolAdmin}>Make Pool Admin</MenuItem>
                                    }
                                    {(user && (user.role === "superadmin") && (user.email !== item.email))
                                        ? <MenuItem onClick={onMakeAdmin}>Make Admin</MenuItem>
                                        : <MenuItem disabled onClick={onMakeAdmin}>Make Admin</MenuItem>
                                    }
                                    {(user && (user.role === "superadmin") && (user.email !== item.email))
                                        ? <MenuItem onClick={onMakeReadOnly}>Make Read Only</MenuItem>
                                        : <MenuItem disabled onClick={onMakeReadOnly}>Make Read Only</MenuItem>
                                    }
                                    {(user && (user.role === "superadmin") && (user.email !== item.email))
                                        ? <MenuItem onClick={onRemove}>Remove</MenuItem>
                                        : <MenuItem disabled onClick={onRemove}>Remove</MenuItem>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}
