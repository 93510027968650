import React from "react";
import { Snackbar } from "@mui/material";
import useAppContextProvider from "../../AppContext/useAppContextProvider";
import { set } from "lodash";
import { StyledIconButton } from "../StyledComponents/Buttons/AuradineButtons";
import CloseIcon from "@mui/icons-material/Close";

export default function Toast() {
    const { toastOpen, toastMessage, setToastOpen, setToastMessage } =
        useAppContextProvider();
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
        setToastMessage("");
    };

    const action = (
        <React.Fragment>
            <StyledIconButton
                size="small"
                aria-label="close"
                onClick={() => {
                    setToastOpen(false);
                    setToastMessage("");
                }}
            >
                <CloseIcon
                    fontSize="small"
                    style={{ color: "rgba(245, 239, 247, 1)" }}
                />
            </StyledIconButton>
        </React.Fragment>
    );
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={toastOpen}
            autoHideDuration={15000}
            onClose={handleClose}
            message={toastMessage}
            action={action}
        />
    );
}
