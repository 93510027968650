import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Box, IconButton } from "@mui/material";

export const SecondaryButton = styled(Button)({
  color: "#2D55C9",
  borderRadius: "2rem",
  paddingLeft: "0.8rem",
  paddingRight: "0.8rem",
  fontSize: "14px",
  fontWeight: 500,
  minWidth: "5rem",
  height: "2.5rem",
  textTransform: "none",
  ":hover": {
    backgroundColor: "rgba(103, 80, 164, 0.08)",
  },
});

export const PrimaryButton = styled(Button)({
  // backgroundColor: "primary.dark !important",
  backgroundColor: "#2D55C9",
  "&:hover": {
    backgroundColor: "#2D55C9", // This sets the hover background color to the same as the normal background color
  },
  borderRadius: "2rem",
  fontSize: "14px",
  fontWeight: 500,
  color: "#FFFFFF",
  minWidth: "5rem",
  height: "2.5rem",
  textTransform: "none",
});

export const StyledButton = styled(Box)({
  minWidth: 84,
  maxWidth: "19rem",
  height: 32,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  display: "inline-flex",
  backgroundColor: "#C7D1FF",
  borderRadius: 8,
  "&:hover": {
    cursor: "pointer",
  },
});

export const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});
