import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import StartOutlinedIcon from "@mui/icons-material/StartOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons";
import { H7TitleLarge } from "../../../components/StyledComponents/Typography/Typography.tsx";
import SiteAndGroupTree from "./SiteAndGroupTree/SiteAndGroupTree.js";
import { set } from "date-fns";

const MUI_X_PRODUCTS = [
  {
    id: "site1",
    label: "Site 1",
    totalMinerCount: 100,
    children: [
      { id: "group1", label: "Group1", minerCount: 50 },
      { id: "group2", label: "Group2", minerCount: 25 },
      { id: "group3", label: "Group3", minerCount: 25 },
    ],
  },
  {
    id: "site2",
    label: "Site 2",
    totalMinerCount: 100,
    children: [
      { id: "group1", label: "Group1", minerCount: 50 },
      { id: "group2", label: "Group2", minerCount: 25 },
      { id: "group3", label: "Group3", minerCount: 25 },
    ],
  },
  {
    id: "site3",
    label: "Site 3",
    totalMinerCount: 100,
    children: [
      { id: "group1", label: "Group1", minerCount: 50 },
      { id: "group2", label: "Group2", minerCount: 25 },
      { id: "group3", label: "Group3", minerCount: 25 },
    ],
  },
  {
    id: "site4",
    label: "Site 4",
    totalMinerCount: 100,
    children: [
      { id: "group2", label: "Group2", minerCount: 25 },
      { id: "group3", label: "Group3", minerCount: 25 },
    ],
  },
];

export const LeftSidePanel = React.memo((props) => {
  const { leftSidePanelOpen, setLeftSidePanelOpen, height } = props;
  const [siteAndGroups, setSiteAndGroups] = React.useState(MUI_X_PRODUCTS);

  const onSitesAndGroupSearch = (e) => {
    if (e.target.value === "") {
      setSiteAndGroups(MUI_X_PRODUCTS);
      return;
    } else {
      const foundProduct = MUI_X_PRODUCTS.filter((product) => {
        return (
          product?.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
          (product.children &&
            product.children.some((child) =>
              child.label.toLowerCase().includes(e.target.value.toLowerCase())
            ))
        );
      });
      console.log("foundProduct", foundProduct);
      setSiteAndGroups(foundProduct);
    }
  };
  const [searchValue, setSearchValue] = React.useState("");
  return (
    <Box
      className={"left-side-panel"}
      display={"flex"}
      flexDirection={"column"}
      gap={4}
      height={height}
      paddingLeft={"0.75rem"}
      paddingRight={"0.75rem"}
      minWidth={leftSidePanelOpen ? "17vw" : "7vw"}
      width={leftSidePanelOpen ? "17vw" : "7vw"}
      flexGrow={leftSidePanelOpen ? 0.1 : 0}
      flexShrink={0}
      borderRight={"1px solid #E5E5E5"}
      sx={{
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        className={"control-button"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={leftSidePanelOpen ? "space-between" : "center"}
      >
        {leftSidePanelOpen && <H7TitleLarge>Sites & Groups</H7TitleLarge>}
        <StyledIconButton
          onClick={() => {
            setLeftSidePanelOpen((prev) => !prev);
          }}
        >
          {leftSidePanelOpen ? (
            <span
              style={{ height: "24px", width: "24px", color: "#000" }}
              className="material-symbols-outlined"
            >
              right_panel_open
            </span>
          ) : (
            <span
              style={{ height: "24px", width: "24px", color: "#000" }}
              className="material-symbols-outlined"
            >
              right_panel_close
            </span>
          )}
        </StyledIconButton>
      </Box>
      {leftSidePanelOpen && (
        <>
          <Collapse in={leftSidePanelOpen} collapsedSize={0}>
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#E8E7F1",
                borderRadius: "1.75rem",
                paddingLeft: "1rem",
              }}
            >
              <Tooltip
                title={searchValue}
                disableFocusListener
                disableTouchListener
              >
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search for a site or group"
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                    onSitesAndGroupSearch(event);
                  }}
                  // multiline
                  // maxRows={4}
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Tooltip>
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <SiteAndGroupTree
              siteAndGroups={siteAndGroups}
              onSitesAndGroupSearch={onSitesAndGroupSearch}
            />
          </Collapse>
        </>
      )}
    </Box>
  );
});
