import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  ClickAwayListener,
  Typography,
  Popover,
  FormControl,
  InputAdornment,
  Input,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import {
  BodyLarge,
  BodySmall,
  H7TitleLarge,
  H8TitleMedium,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import {
  PrimaryButton,
  SecondaryButton,
  StyledIconButton,
} from "../../../../components/StyledComponents/Buttons/AuradineButtons";
import TableChartIcon from "@mui/icons-material/TableChartOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useState } from "react";
import useAppContextProvider from "../../../../AppContext/useAppContextProvider.js";
import { useMutation } from "@tanstack/react-query";
import { GetUserUseQuery, postNewPreferences } from "../../../../api/api.js";
import useMinerPageContext from "../../../context/useMinerPageContext.js";
import { returnDefaultColumnConfigs } from "../../../Utils/Utils.js";
import { isEqual, set } from "lodash";

const StyledPopoverContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #f3f2fd;
  border-radius: 1rem;
  max-width: 35.5625rem;
  width: 35.5625rem; // Change this to the border radius you want

  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 1rem;
    max-width: 35.5625rem;
    width: 35.5625rem; // Change this to the border radius you want
  }
`;

const StyledHeaderAndFooterBox = styled(Box)`
  padding: 1.5rem;
`;

const ColumnsCard = (props) => {
  const { open, handleClose, anchorRef } = props;
  const [showResetButton, setShowResetButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [severityLevel, setSeverityLevel] = useState("");
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const { authToken } = useAppContextProvider();
  const { tableGroup, detailGroup, setTableGroup, setDetailGroup } =
    useMinerPageContext();
  const { setToastMessage, setToastOpen } = useAppContextProvider();
  const defaultColumnConfigs = returnDefaultColumnConfigs();
  const { tableGroup: defaultTableGroup, detailGroup: defaultDetailGroup } =
    defaultColumnConfigs;

  const copiedRef = React.useRef(false);

  let [currentTableGroup, setCurrentTableGroup] = React.useState([]);
  let [currentDetailGroup, setCurrentDetailGroup] = React.useState([]);

  React.useEffect(() => {
    if (!copiedRef.current && tableGroup.length > 0 && detailGroup.length > 0) {
      setCurrentTableGroup(tableGroup);
      setCurrentDetailGroup(detailGroup);
      copiedRef.current = true;
    }
  }, [tableGroup, detailGroup]);

  //we always copy the initial state of tableGroup and detailGroup to currentTableGroup and currentDetailGroup so that users can cancel the changes that they performed.

  // if tablegroup and detailGroup values change, we need to show cancel button. if table group and detail group values are different compared to default values we need to show reset button or otherwise hide it
  React.useEffect(() => {
    //if the length of the tableGroup and detailGroup is different from the default values, show the reset button
    // if the values are not same as the default values, show the reset button
    if (
      !isEqual(tableGroup, defaultTableGroup) ||
      !isEqual(detailGroup, defaultDetailGroup)
    ) {
      setShowResetButton(true);
    }
  }, [tableGroup, detailGroup]);

  // Reset button handler. This will reset the columns to the default values
  const resetButtonHandler = React.useCallback(() => {
    setShowAlert(true);
    setSeverityLevel("info");
    setAlertText("Columns reset to default");
    setTableGroup(defaultTableGroup);
    setDetailGroup(defaultDetailGroup);
    setShowResetButton(false);
  }, [defaultTableGroup, defaultDetailGroup]);

  //Drag and drop logic. This is used to reorder the columns
  // in the table and the details view
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const start = source.droppableId;
    const finish = destination.droppableId;
    if (start === finish) {
      const group = start === "tableGroup" ? tableGroup : detailGroup;
      const newGroup = Array.from(group);
      const [removed] = newGroup.splice(source.index, 1);
      newGroup.splice(destination.index, 0, removed);

      if (start === "tableGroup") {
        setTableGroup(newGroup);
      } else {
        setDetailGroup(newGroup);
      }
    } else {
      const startGroup = start === "tableGroup" ? tableGroup : detailGroup;
      const finishGroup = start === "tableGroup" ? detailGroup : tableGroup;
      const newStartGroup = Array.from(startGroup);
      const newFinishGroup = Array.from(finishGroup);
      const [removed] = newStartGroup.splice(source.index, 1);
      newFinishGroup.splice(destination.index, 0, removed);
      if (start === "tableGroup") {
        setTableGroup(newStartGroup);
        setDetailGroup(newFinishGroup);
      } else {
        setTableGroup(newFinishGroup);
        setDetailGroup(newStartGroup);
      }
    }
  };

  // Mutation to post the new preferences. Read https://tanstack.com/query/v4/docs/framework/react/reference/useMutation documentation. https://tanstack.com/query/v4/docs/framework/react/guides/mutations for examples.
  const mutation = useMutation({
    mutationFn: postNewPreferences,
    onSuccess: () => {
      setToastOpen(true);
      setToastMessage("Columns saved successfully");
      // setSeverityLevel("success");
      handleClose(); // Close the modal or perform other success actions
    },
    onError: () => {
      setToastOpen(true);
      setToastMessage("Failed to save columns");
      // setSeverityLevel("error"); // Show error message
    },
  });

  // On save we check always for the tableGroup length.  If the length matches our criteria we save the data
  const saveButtonHandler = React.useCallback(() => {
    if (tableGroup.length > 6) {
      setShowAlert(true);
      setAlertText("You can only select up to 6 columns on the table");
      setSeverityLevel("error");

      return;
    }
    if (tableGroup.length < 6) {
      setShowAlert(true);
      setAlertText("You must select at least 6 columns on the table");
      setSeverityLevel("error");

      return;
    } else {
      const dataToBeSent = { tableGroup, detailGroup };
      mutation.mutate({
        authToken,
        body: { user: { ...dataToBeSent } },
      });
      // handleClose();
    }
  }, [tableGroup, detailGroup, authToken]);

  //on cancel we set the tableGroup and detailGroup to the currentTableGroup and currentDetailGroup which are the copies we save initially
  const cancelButtonHandler = React.useCallback(() => {
    setTableGroup([...currentTableGroup]);
    setDetailGroup([...currentDetailGroup]);
    setDisableCancelButton(true);
  }, [tableGroup, detailGroup]);

  // Original list of groups (used for resetting filter)

  return (
    <StyledPopover
      sx={{ zIndex: 9999, borderRadius: "1rem" }}
      open={open}
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <StyledPopoverContent>
          <StyledHeaderAndFooterBox
            display={"flex"}
            flexDirection={"column"}
            gap={4}
          >
            <H7TitleLarge>Manage columns</H7TitleLarge>
            <BodySmall>
              Select and arrange up to 6 columns in the table. For details on
              additional columns, select a miner to access a level 2 view.
            </BodySmall>
          </StyledHeaderAndFooterBox>
          {showAlert && (
            <Box
              padding={"0 0.5rem 0.5rem 0.5rem"}
              display={"flex"}
              flexGrow={0}
            >
              <Alert
                severity={severityLevel || "error"}
                onClose={() => setShowAlert(false)}
              >
                {alertText}
              </Alert>
            </Box>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              className={"DragDropContext"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                flexGrow={1}
                flexShrink={1}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"0rem 1rem"}
                >
                  <H8TitleMedium>Details</H8TitleMedium>
                  <BodySmall> Shown when miner is selected</BodySmall>
                </Box>
                <Droppable droppableId="detailsGroup">
                  {(provided) => (
                    <>
                      <List
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {detailGroup.map((group, index) => (
                          <Draggable
                            key={group?.id}
                            index={index}
                            draggableId={`detailsGroup-${group?.id}`}
                          >
                            {(provided, snapshot) => (
                              <ListItem
                                key={index}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                disableGutters
                                sx={{
                                  padding: "0.5rem 1.5rem 0.5rem 1rem",
                                  //   maxWidth: "14.25rem",
                                  maxHeight: "2.5rem",
                                  backgroundColor: snapshot.isDragging
                                    ? "#FAF8FF"
                                    : "#f3f2fd",
                                  boxShadow: snapshot.isDragging
                                    ? "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)"
                                    : "none",
                                  "::after": snapshot.isDragging
                                    ? {
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        backgroundColor:
                                          "rgba(29, 27, 32, 0.16)", // Change this to the color you want
                                      }
                                    : {},
                                }}
                              >
                                <ListItemText>
                                  <BodyLarge>{group?.name}</BodyLarge>
                                </ListItemText>
                                <DragIndicatorIcon />
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    </>
                  )}
                </Droppable>
              </Box>
              <Box>
                <Divider orientation="vertical">
                  <SwapHorizOutlinedIcon />
                </Divider>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                flexGrow={1}
                flexShrink={1}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"0rem 1rem"}
                >
                  <H8TitleMedium>On table</H8TitleMedium>
                  <BodySmall>
                    {" "}
                    Displayed in this order from left to right
                  </BodySmall>
                </Box>
                <Droppable droppableId="tableGroup">
                  {(provided) => (
                    <>
                      <List
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tableGroup.map((group, index) => (
                          <Draggable
                            key={group?.id}
                            index={index}
                            draggableId={`tableGroup-${group?.id}`}
                          >
                            {(provided, snapshot) => (
                              <ListItem
                                key={index}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                disableGutters
                                sx={{
                                  padding: "0.5rem 1.5rem 0.5rem 1rem",
                                  //   maxWidth: "14.25rem",
                                  maxHeight: "2.5rem",
                                  backgroundColor: snapshot.isDragging
                                    ? "#FAF8FF"
                                    : "#f3f2fd",
                                  boxShadow: snapshot.isDragging
                                    ? "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)"
                                    : "none",
                                  "::after": snapshot.isDragging
                                    ? {
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        backgroundColor:
                                          "rgba(29, 27, 32, 0.16)", // Change this to the color you want
                                      }
                                    : {},
                                }}
                              >
                                <ListItemText>
                                  <BodyLarge>{group?.name}</BodyLarge>
                                </ListItemText>
                                <DragIndicatorIcon />
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    </>
                  )}
                </Droppable>
              </Box>
            </Box>
          </DragDropContext>
          <Box
            className={"popover-actions"}
            padding={"1.5rem 1.5rem 1.5rem 1rem"}
            display={"flex"}
            flexGrow={1}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            {showResetButton ? (
              <SecondaryButton onClick={resetButtonHandler}>
                Reset
              </SecondaryButton>
            ) : (
              <Box></Box>
            )}
            <Box
              className={"popover-actions"}
              display={"flex"}
              justifyContent={"flex-end"}
              gap={2}
            >
              <SecondaryButton
                disabled={
                  isEqual(tableGroup, currentTableGroup) &&
                  isEqual(detailGroup, currentDetailGroup)
                }
                onClick={cancelButtonHandler}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={saveButtonHandler}>Save</PrimaryButton>
            </Box>
          </Box>
        </StyledPopoverContent>
      </ClickAwayListener>
    </StyledPopover>
  );
};

export default ColumnsCard;

export const ManageColumns = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          // paddingRight: "2rem",
        }}
      >
        <Tooltip title="Manage columms">
          <StyledIconButton ref={anchorRef} onClick={handleClick}>
            <TableChartIcon fontSize={"medium"} sx={{ color: "#000000" }} />
          </StyledIconButton>
        </Tooltip>
      </Box>

      <ColumnsCard
        open={open}
        handleClose={handleClose}
        anchorRef={anchorRef}
      />
    </React.Fragment>
  );
};
