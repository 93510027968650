const ENCRYPTION_ALGORITHM = "AES-GCM";
const IV_LENGTH = 12; // Recommended length for AES-GCM

// Generate an encryption key
export async function generateKey() {
    return await crypto.subtle.generateKey(
        { name: ENCRYPTION_ALGORITHM, length: 256 },
        true,
        ["encrypt", "decrypt"]
    );
}

// Convert a string to ArrayBuffer
function strToBuffer(str) {
    return new TextEncoder().encode(str);
}

// Convert ArrayBuffer to string
function bufferToStr(buffer) {
    return new TextDecoder().decode(buffer);
}

// Encrypt text
export async function encryptText(text, key) {
    const iv = crypto.getRandomValues(new Uint8Array(IV_LENGTH));
    const encryptedContent = await crypto.subtle.encrypt(
        { name: ENCRYPTION_ALGORITHM, iv },
        key,
        strToBuffer(text)
    );

    return {
        iv: btoa(String.fromCharCode(...new Uint8Array(iv))),
        content: btoa(String.fromCharCode(...new Uint8Array(encryptedContent)))
    };
}

// Decrypt text
export async function decryptText(encryptedContent, ivBase64, key) {
    const iv = Uint8Array.from(atob(ivBase64), c => c.charCodeAt(0));
    const encryptedBuffer = Uint8Array.from(atob(encryptedContent), c => c.charCodeAt(0));

    const decryptedContent = await crypto.subtle.decrypt(
        { name: ENCRYPTION_ALGORITHM, iv },
        key,
        encryptedBuffer
    );

    return bufferToStr(decryptedContent);
}

// Export CryptoKey to base64 for storage or transfer
export async function exportKeyToBase64(key) {
    const exported = await crypto.subtle.exportKey("raw", key);
    return btoa(String.fromCharCode(...new Uint8Array(exported)));
}

// Import CryptoKey from base64
export async function importKeyFromBase64(base64Key) {
    const keyBuffer = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0));
    return await crypto.subtle.importKey(
        "raw",
        keyBuffer,
        { name: ENCRYPTION_ALGORITHM },
        true,
        ["encrypt", "decrypt"]
    );
}